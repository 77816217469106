import { AnalysisTargetGroupRadio, AnalysisTargetGroupTV } from "../api";

// eslint-disable-next-line import/prefer-default-export
export const targetGroupDisplayName = (
  tg: AnalysisTargetGroupTV & AnalysisTargetGroupRadio
) => {
  if (tg.b2B && tg.age.min) {
    return "B-to-B";
  }
  if (tg.hhv && tg.kND12 && tg.age.min) {
    return "HHV + KND 0-12";
  }
  if (tg.hhv && tg.kND17 && tg.age.min) {
    return "HHV + KND";
  }
  if (tg.hHVKND012 && tg.age.min === 6) {
    return "HHV + KND 0-12";
  }
  if (tg.zb && tg.age.min === 6) {
    return "ZB";
  }

  return `${tg.gender && tg.gender !== "X" ? `${tg.gender} ` : ""}${
    tg.age.min
  }${tg.age.max && tg.age.max !== 97 ? `-${tg.age.max}` : "+"}${
    tg.aB1 ? " AB1" : ""
  }${tg.b2B ? " B-to-B" : ""}${tg.dag ? " DAG" : ""}${tg.ehb ? " EHB" : ""}${
    tg.hhv ? " HHV" : ""
  }${tg.kND12 ? " + KND 0-12" : ""}${tg.kND17 ? " + KND" : ""}${
    tg.w1 ? " W1" : ""
  }${tg.zzp ? " ZZP" : ""}`;
};
