import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Icons, Select, Typography } from "@ster/ster-toolkit";
import { App as AntApp } from "antd";
import { memo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { OrderListItem, ProposalType } from "../../api";
import { ReduxStoreState } from "../../store/base";
import {
  clearProposalGenerateAction,
  proposalGenerateAction,
} from "../../store/campaignCreate/actions";
import { usePrevious } from "../../utils/hooks";
import { canProposeMultipleCampaigns } from "../../utils/userHelper";
import { proposalRequestSelector } from "./selectors";

const ProposalRequest = memo(
  ({
    selectedCampaigns,
    clearSelectCampaigns,
  }: {
    selectedCampaigns: OrderListItem[];
    clearSelectCampaigns?: () => void;
  }) => {
    const { i18n } = useLingui();
    const dispatch = useDispatch();
    const { modal, message } = AntApp.useApp();

    const { account, proposalState } = useSelector(proposalRequestSelector);

    const proposalGenerate = useCallback(
      (proposalType: ProposalType) => {
        dispatch(
          proposalGenerateAction.request({
            type: proposalType,
            initialRequests: selectedCampaigns.map((s) => ({
              from: new Date(s.startDate),
              to: new Date(s.endDate),
              initialRequestId: s.id,
              medium: s.medium,
            })),
          })
        );
      },
      [dispatch, selectedCampaigns]
    );

    const prevState = usePrevious(proposalState);
    useEffect(() => {
      if (prevState === ReduxStoreState.Loading) {
        if (proposalState === ReduxStoreState.Success) {
          message.success(
            i18n._(
              t`Het voorstel wordt naar je verstuurd, dit kan enkele minuten duren.`
            )
          );
        }

        if (
          proposalState === ReduxStoreState.Success ||
          proposalState === ReduxStoreState.Failure
        ) {
          clearSelectCampaigns?.();
          dispatch(clearProposalGenerateAction());
        }
      }
    }, [
      clearSelectCampaigns,
      dispatch,
      i18n,
      message,
      prevState,
      proposalState,
    ]);

    const handleProposalGenerateSelected = useCallback(() => {
      let proposalType: ProposalType = ProposalType.FlightsWithSum;
      modal.confirm({
        title: i18n._(t`Voorstel aanvragen`),
        content: (
          <>
            <Typography.Paragraph>
              {i18n._(
                t`Wil je deze concepten als voorstel in je mail ontvangen?`
              )}
              <br />
              {i18n._(
                t`Je ontvangt binnen enkele minuten het voorstel op het bij ons bekende e-mailadres`
              )}{" "}
              <strong>{account.sterInlog?.email}</strong>.
            </Typography.Paragraph>
            {canProposeMultipleCampaigns(account) && (
              <Typography.Paragraph>
                Soort voorstel
                <Select
                  defaultValue="FlightsWithSum"
                  onChange={(val) => {
                    proposalType = val as ProposalType;
                  }}
                  options={[
                    {
                      label: "Verschillende flights",
                      value: ProposalType.Flights,
                    },
                    {
                      label: "Verschillende flights + totaal",
                      value: ProposalType.FlightsWithSum,
                    },
                    {
                      label: "Verschillende scenario's",
                      value: ProposalType.Scenarios,
                    },
                  ]}
                />
              </Typography.Paragraph>
            )}
            <Typography.Footnote>
              {i18n._(
                t`Indien je het voorstel niet ontvangen hebt, controleer dan eerst je spam folder.`
              )}
            </Typography.Footnote>
          </>
        ),
        onOk: () => {
          proposalGenerate(proposalType);
        },
        onCancel: () => {
          clearSelectCampaigns?.();
        },
        cancelText: i18n._(t`Annuleren`),
        okText: i18n._(t`Voorstel aanvragen`),
      });
    }, [account, clearSelectCampaigns, i18n, modal, proposalGenerate]);

    return (
      <Button
        mode="tertiary"
        disabled={selectedCampaigns.length === 0}
        onClick={handleProposalGenerateSelected}
        loading={proposalState === ReduxStoreState.Loading}
      >
        <Icons.PdfIcon fill="#008ccc" />
        <Trans>Voorstel voor {selectedCampaigns.length} concept(en)</Trans>
      </Button>
    );
  }
);

export default ProposalRequest;
