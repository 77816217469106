/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  EditableSetting,
  EditableSettingFromJSON,
  EditableSettingToJSON,
  ProblemDetails,
  ProblemDetailsFromJSON,
  ProblemDetailsToJSON,
  SettingsResult,
  SettingsResultFromJSON,
  SettingsResultToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface ApiV1SettingsPutRequest {
  editableSetting?: EditableSetting;
}

/**
 *
 */
export class SettingsApi extends runtime.BaseAPI {
  /**
   */
  async apiV1SettingsEditableGetRaw(): Promise<
    runtime.ApiResponse<Array<EditableSetting>>
  > {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Settings/editable`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(EditableSettingFromJSON)
    );
  }

  /**
   */
  async apiV1SettingsEditableGet(): Promise<Array<EditableSetting>> {
    const response = await this.apiV1SettingsEditableGetRaw();
    return await response.value();
  }

  /**
   */
  async apiV1SettingsGetRaw(): Promise<runtime.ApiResponse<SettingsResult>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Settings`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SettingsResultFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1SettingsGet(): Promise<SettingsResult> {
    const response = await this.apiV1SettingsGetRaw();
    return await response.value();
  }

  /**
   */
  async apiV1SettingsPutRaw(
    requestParameters: ApiV1SettingsPutRequest
  ): Promise<runtime.ApiResponse<EditableSetting>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Settings`,
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: EditableSettingToJSON(requestParameters.editableSetting),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EditableSettingFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1SettingsPut(
    requestParameters: ApiV1SettingsPutRequest
  ): Promise<EditableSetting> {
    const response = await this.apiV1SettingsPutRaw(requestParameters);
    return await response.value();
  }
}
