import { AnyAction } from "redux";
import { Reducer, createReducer } from "typesafe-actions";

import { ReduxStoreState } from "../base";
import { LoadingAdvertisers } from "../models";
import { receiveAdvertisersAction } from "./actions";

// eslint-disable-next-line import/prefer-default-export
export const advertisersReducer: Reducer<LoadingAdvertisers, AnyAction> =
  createReducer({
    state: ReduxStoreState.Initial,
  })
    .handleAction(
      receiveAdvertisersAction.request,
      (state: LoadingAdvertisers) => ({
        ...state,
        loading: true,
        state: ReduxStoreState.Loading,
      })
    )
    .handleAction(
      receiveAdvertisersAction.failure,
      (state: LoadingAdvertisers) => ({
        ...state,
        loading: false,
        state: ReduxStoreState.Failure,
      })
    )
    .handleAction(
      receiveAdvertisersAction.success,
      (
        state: LoadingAdvertisers,
        action: ReturnType<typeof receiveAdvertisersAction.success>
      ) => ({
        advertisers: action.payload || [],
        loading: false,
        state: ReduxStoreState.Success,
      })
    );
