import { createAsyncAction } from "typesafe-actions";

import { Organisation } from "../../api/models";
import { ResponseError } from "../base";
import { actionNameAsync } from "../utils";

// eslint-disable-next-line import/prefer-default-export
export const receiveOrganisationsAction = createAsyncAction(
  actionNameAsync("organisations", "REQUEST"),
  actionNameAsync("organisations", "SUCCESS"),
  actionNameAsync("organisations", "FAILURE")
)<undefined, Organisation[], ResponseError>();
