import { Plural, Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  ContentContainer,
  Form,
  Switch,
  Tag,
  Typography,
} from "@ster/ster-toolkit";
import { Alert, Form as AntForm } from "antd";
import { Store } from "antd/lib/form/interface";
import { memo, useCallback, useEffect, useMemo } from "react";

import { MediumEnum, PackagePropertiesRadio } from "../../../../api";
import { TagStatus, hasKey } from "../../../../utils";
import { usePrevious } from "../../../../utils/hooks";
import { formItemLayout } from "../../../partials/Form";
import ChannelChoice from "./ChannelChoice";
import DaySelection from "./DaySelection";
import { PackageOptionsRadioProps } from "./models";
import styles from "./PackageOptions.module.less";
import TimeSelection from "./TimeSelection";

type PackageSwitchProperties = {
  name: string;
  label?: React.ReactNode;
  helpText?: string;
  selectedPackageProperties?: PackagePropertiesRadio;
  packagesProperties: PackagePropertiesRadio[];
};

const PackageSwitch = memo(
  ({
    name,
    label,
    helpText,
    selectedPackageProperties,
    packagesProperties,
  }: PackageSwitchProperties) => {
    const { i18n } = useLingui();

    const enabledForOption = useCallback(
      (option: string, toggled: boolean) => {
        if (!selectedPackageProperties) {
          return false;
        }

        // Bepaal of er een pakket beschikbaar met de betreffende optie
        const newSelection = {
          ...selectedPackageProperties,
          [option]: toggled,
        };

        return (
          packagesProperties.filter(
            ({ hasSpread, hasHours, hasCombi, hasChannelChoice, hasDays }) =>
              hasSpread === newSelection.hasSpread &&
              hasHours === newSelection.hasHours &&
              hasCombi === newSelection.hasCombi &&
              hasChannelChoice === newSelection.hasChannelChoice &&
              hasDays === newSelection.hasDays
          ).length > 0
        );
      },
      [packagesProperties, selectedPackageProperties]
    );

    return (
      <Form.Item
        label={label}
        name={name}
        valuePropName="checked"
        helpText={helpText}
      >
        <Switch
          disabled={
            !enabledForOption(
              name,
              selectedPackageProperties !== undefined &&
                hasKey(selectedPackageProperties, name) &&
                !selectedPackageProperties[name]
            )
          }
          checkedChildren={i18n._(t`aan`)}
          unCheckedChildren={i18n._(t`uit`)}
        />
      </Form.Item>
    );
  }
);

/**
 * Dropdown met pakketopties voor radio
 */
const PackageOptionsRadio = memo(
  ({
    packages,
    selectedPackage,
    groupedPackage,
    groupedPackage: { properties: packagesProperties },
    subOrder,
    onChange,
    onFinishFailed,
    channels,
    hasSalesLock,
    enabledDaysOfWeek,
  }: PackageOptionsRadioProps) => {
    const { i18n } = useLingui();
    const [form] = AntForm.useForm();
    const previousPackageCode = usePrevious(subOrder._package?.code);
    const handleValuesChange = useCallback(
      (changedValues: Store, values: Store) => {
        if (
          [
            "hasSpread",
            "hasHours",
            "hasCombi",
            "hasChannelChoice",
            "hasDays",
          ].find((key) => hasKey(changedValues, key)) !== undefined
        ) {
          const packageProperties = packagesProperties.find(
            ({ hasSpread, hasHours, hasCombi, hasChannelChoice, hasDays }) =>
              hasSpread === (values.hasSpread ?? false) &&
              hasHours === (values.hasHours ?? false) &&
              hasCombi === (values.hasCombi ?? false) &&
              hasChannelChoice === (values.hasChannelChoice ?? false) &&
              hasDays === (values.hasDays ?? false)
          );

          onChange(
            {
              packageCode: packageProperties?.code,
              ...changedValues,
            },
            values
          );
        } else {
          onChange(changedValues, values);
        }
      },
      [packagesProperties, onChange]
    );

    const selectedPackageProperties = useMemo(
      () =>
        packagesProperties.find(({ code }) => code === subOrder._package?.code),
      [packagesProperties, subOrder._package?.code]
    );

    useEffect(() => {
      if (subOrder._package?.code !== previousPackageCode) {
        form.setFieldsValue({
          ...subOrder,
        });
      }
    }, [form, previousPackageCode, subOrder]);

    useEffect(() => {
      if (selectedPackageProperties) {
        form.setFieldsValue({
          ...selectedPackageProperties,
        });
      }
    }, [form, selectedPackageProperties]);

    const showChannelChoice = useMemo(
      () =>
        packagesProperties.find(({ hasChannelChoice }) => hasChannelChoice) !==
          undefined ||
        (selectedPackage?.hasChannelChoice && channels.length > 1),
      [packagesProperties, selectedPackage, channels]
    );

    return (
      <div className={styles.container}>
        <Typography.Title level={3} className={styles.title}>
          <Trans>Extra opties</Trans>
        </Typography.Title>
        <ContentContainer className={styles.options}>
          <Form
            {...formItemLayout}
            form={form}
            onValuesChange={handleValuesChange}
            name={`packageOptionsRadio-${subOrder.id}`}
            onFinishFailed={onFinishFailed}
            initialValues={{
              ...subOrder,
              ...selectedPackageProperties,
            }}
          >
            {packagesProperties.find(({ hasSpread }) => hasSpread) !==
              undefined && (
              <PackageSwitch
                label={i18n._(t`Spreiding`)}
                name="hasSpread"
                helpText={i18n._(t`Met de optie Spreiding kun je een gelijk aantal spots per
                zender per dag verwachten.`)}
                packagesProperties={packagesProperties}
                selectedPackageProperties={selectedPackageProperties}
              />
            )}
            {packagesProperties.find(({ hasHours }) => hasHours) !==
              undefined && (
              <Form.Item
                label={i18n._(t`Tijdvak`)}
                helpText={i18n._(t`Met de optie Tijdvak bepaal je op welk moment van de dag je
              ingedeeld wordt, minimaal 7 aaneengesloten uren per dag.`)}
              >
                <PackageSwitch
                  name="hasHours"
                  packagesProperties={packagesProperties}
                  selectedPackageProperties={selectedPackageProperties}
                />
                {selectedPackageProperties?.hasHours &&
                  selectedPackage &&
                  selectedPackage.minDailyHours > 0 && (
                    <TimeSelection
                      _package={selectedPackage}
                      medium={MediumEnum.Radio}
                    />
                  )}
              </Form.Item>
            )}
            {selectedPackage &&
              packagesProperties.find(({ hasDays }) => hasDays) !==
                undefined && (
                <Form.Item
                  label={i18n._(t`Dagen`)}
                  helpText={
                    <Trans>
                      Met de optie Dagen bepaal je op welke dagen van de week je
                      ingedeeld wordt, minimaal{" "}
                      <Plural
                        value={selectedPackage.minDaysNoWeekly}
                        one="# dag"
                        other="# dagen"
                      />{" "}
                      per week.
                    </Trans>
                  }
                >
                  <PackageSwitch
                    name="hasDays"
                    packagesProperties={packagesProperties}
                    selectedPackageProperties={selectedPackageProperties}
                  />
                  {selectedPackageProperties?.hasDays &&
                    selectedPackage &&
                    selectedPackage.minDaysNoWeekly > 0 &&
                    selectedPackage.minDaysNoWeekly < 7 && (
                      <DaySelection
                        _package={selectedPackage}
                        enabledDaysOfWeek={enabledDaysOfWeek}
                        enabled={!!subOrder.period}
                        shortDayNames
                      />
                    )}
                </Form.Item>
              )}
            {packagesProperties.find(({ hasCombi }) => hasCombi) !==
              undefined && (
              <PackageSwitch
                label={i18n._(t`Combinatie`)}
                name="hasCombi"
                helpText={i18n._(t`Met de optie Combinatie wordt je ingedeeld op Radio 2 en 3FM, of op Radio 1, 2, 3FM, 5 en de Regio.
                Wil je sturing geven aan de verdeling van het aantal spots
                en/of zenders en/of budget dan boek je de afzonderlijke
                zenders.`)}
                packagesProperties={packagesProperties}
                selectedPackageProperties={selectedPackageProperties}
              />
            )}
            {showChannelChoice && (
              <ChannelChoice
                groupedPackage={groupedPackage}
                selectedPackageProperties={selectedPackageProperties}
                channels={channels}
                selectedPackage={selectedPackage}
              />
            )}

            {packagesProperties.length > 1 && (
              <Form.Item label={i18n._(t`Gekozen pakket`)}>
                <span>
                  {subOrder._package?.name}
                  <Tag
                    className={styles.tag}
                    status={TagStatus.Info}
                    text={`${i18n._(t`Pakketindex`)}: ${
                      packages.find(
                        ({ code }) => code === subOrder._package?.code
                      )?.productIndex ?? 0
                    }`}
                  />
                </span>
              </Form.Item>
            )}
          </Form>
          {hasSalesLock && (
            <Alert
              showIcon
              type="info"
              message=""
              className="submitAlert"
              description={
                groupedPackage.name.toLocaleLowerCase().includes("kansspel") ? (
                  <Trans>
                    In verband met de huidige regelgeving rondom
                    kansspeladverteerders zijn niet alle pakketten beschikbaar.
                  </Trans>
                ) : (
                  <Trans>
                    In verband met een verkoopstop zijn niet alle pakketten
                    beschikbaar.
                  </Trans>
                )
              }
            />
          )}
        </ContentContainer>
      </div>
    );
  }
);

export default PackageOptionsRadio;
