import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  ComponentLocalization,
  Form,
  RangePicker,
  Spinner,
} from "@ster/ster-toolkit";
import { Alert, Form as AntForm } from "antd";
import { Store } from "antd/lib/form/interface";
import classNames from "classnames";
import { differenceInDays } from "date-fns";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  BreakSelection,
  PackageChoiceEnum,
  SubOrderRequest,
} from "../../../api";
import { ReduxStoreState } from "../../../store/base";
import { receiveSpotIndexesAction } from "../../../store/campaignCreate/actions";
import { StoreModel } from "../../../store/models";
import {
  getLanguage,
  getPopupContainerSubOrders,
  getSpotPrice,
} from "../../../utils";
import { isBetweenDays } from "../../../utils/dateHelper";
import { formItemLayout } from "../../partials/Form";
import DynamicIndexWarning from "../DynamicIndexWarning";
import { SubOrderEditProps } from "./models";
import BreakSelectionInput from "./partials/BreakSelectionInput";
import ExcludeDays from "./partials/ExcludeDays";
import SpotLength from "./partials/SpotLength";
import { fixedCostsSubOrderSelector } from "./selectors";
import styles from "./SubOrder.module.less";

/**
 * Toevoegen/wijzigen van een pakket van het type vaste kosten per spot
 */
const FixedCostsSubOrder = memo(
  ({
    onChange,
    subOrder,
    productId,
    onFinishFailed,
    period,
    selectedSalesPeriod,
    medium,
    alreadySelected,
  }: SubOrderEditProps) => {
    const { i18n } = useLingui();
    const [form] = AntForm.useForm();
    const dispatch = useDispatch();
    const [isDirty, setIsDirty] = useState<boolean>(false);

    const {
      spotIndexes,
      package: selectedPackage,
      loading,
      canRequest,
      states: { spotIndexes: spotIndexesState },
    } = useSelector((state: StoreModel) =>
      fixedCostsSubOrderSelector(state, {
        period,
        medium,
        packageChoice: subOrder.packageChoice,
      })
    );

    // Haal spotindexen op
    useEffect(() => {
      if (spotIndexesState === ReduxStoreState.Initial) {
        dispatch(
          receiveSpotIndexesAction.request({
            // TODO: spotindexen per medium in store?
            medium,
            year: period.from.getFullYear(),
          })
        );
      }
    }, [dispatch, medium, period.from, spotIndexesState]);

    useEffect((): void => {
      if (subOrder) {
        // Vul het formulier met de bestaande deelorder
        form.setFieldsValue({
          spotLength: [undefined], // Spotlength is altijd minimaal een leeg array om de control te initialiseren
          ...subOrder,
          period: subOrder.period
            ? [subOrder.period.from, subOrder.period.to]
            : undefined,
          targetGroupId: subOrder.targetGroup?.id,
        });

        // Re-validate de spotlength
        const currentSpotLength = (
          (form.getFieldValue("spotLength") ?? []) as (number | undefined)[]
        ).filter((l) => l !== undefined);
        if (currentSpotLength.length > 0) {
          form.validateFields(["spotLength"]);
        }
      }
    }, [subOrder, form]);

    // Controleer of alle generieke velden (niet-budget of spot-kiezer) zijn gevuld
    const showBreakSelection = useCallback(
      () =>
        subOrder.period &&
        (subOrder.spotLength?.reduce((a, b) => a + (b ?? 0)) ?? 0) > 0,
      [subOrder]
    );

    // Wijzigen van het formulier
    const handleValuesChange = useCallback(
      (changedValues: Store, values: Store) => {
        const newValues: Partial<SubOrderRequest> = {
          ...values,
          id: subOrder.id,
          period: values.period
            ? {
                from: values.period[0],
                to: values.period[1],
              }
            : undefined,
          budget: values.breakSelection?.reduce(
            (a: number, b: BreakSelection) =>
              a +
              (getSpotPrice(
                b,
                selectedPackage?.preferredPositionSurcharge ?? 0
              ) ?? 0),
            0
          ),
        };
        onChange(newValues);

        if (
          showBreakSelection() &&
          (changedValues.spotLength ||
            changedValues.period ||
            changedValues.excluded)
        ) {
          // Als de spotlengte of periode wijzigt dienen de blokken herberekent te worden
          setIsDirty(true);
        }
      },
      [
        subOrder.id,
        onChange,
        showBreakSelection,
        selectedPackage?.preferredPositionSurcharge,
      ]
    );

    // Afhandelen van het optreden van een error bij het versturen van het formulier
    const handleFinishFailed = useCallback(() => {
      onFinishFailed(subOrder.id ?? 0);
    }, [onFinishFailed, subOrder]);

    // Bepaling van de niet beschikbare data in de periode-kiezer
    const isDateDisabled = useCallback(
      (currentDate: Date): boolean =>
        !isBetweenDays(
          selectedSalesPeriod.salesPeriodStartDate,
          selectedSalesPeriod.salesPeriodEndDate,
          currentDate
        ),
      [
        selectedSalesPeriod.salesPeriodEndDate,
        selectedSalesPeriod.salesPeriodStartDate,
      ]
    );

    const handleBreakSelectionChange = useCallback(
      (value: BreakSelection[]) => {
        form.setFieldsValue({ breakSelection: value });
        setIsDirty(false);
      },
      [form]
    );

    const minDays = useMemo(
      () => selectedPackage?.minDays ?? 0,
      [selectedPackage?.minDays]
    );

    return (
      <Spinner spinning={loading}>
        <Form
          {...formItemLayout}
          onValuesChange={handleValuesChange}
          onFinishFailed={handleFinishFailed}
          form={form}
          name={`suborder-${subOrder.id}`}
          className={classNames(styles.fixedcosts, "fixedWidthLabels")}
        >
          <Form.Item
            label={i18n._(t`Periode`)}
            name="period"
            rules={[
              {
                required: true,
                message: i18n._(t`Kies een periode`),
              },
              {
                validator: (_, value): Promise<void> =>
                  minDays === 0 ||
                  differenceInDays(value?.[1], value?.[0]) + 1 >= minDays
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          i18n._(
                            t`Je dient minimaal ${minDays} dagen te selecteren`
                          )
                        )
                      ),
              },
            ]}
            extra={minDays > 0 ? i18n._(t`Minimaal ${minDays} dagen`) : null}
          >
            <RangePicker
              showWeek
              disabledDate={isDateDisabled}
              defaultPickerValue={[
                selectedSalesPeriod.salesPeriodStartDate,
                null!,
              ]}
              componentLocale={getLanguage() as ComponentLocalization}
              getPopupContainer={getPopupContainerSubOrders}
            />
          </Form.Item>
          {subOrder.packageChoice !== PackageChoiceEnum.FixedCostsCult && (
            <Form.Item label={i18n._(t`Uitgesloten dagen`)} name="excluded">
              <ExcludeDays
                period={subOrder.period}
                weekDays={selectedPackage?.weekDays}
                canDisableSeperateDays
                disabled={!subOrder.period}
                disabledText={<Trans>Selecteer eerst een periode</Trans>}
              />
            </Form.Item>
          )}
          <Form.Item label={i18n._(t`Spotlengte`)}>
            <SpotLength
              spotIndexes={spotIndexes}
              spotLength={subOrder.spotLength ?? []}
              enabled={subOrder.period !== undefined}
            />
          </Form.Item>
          {showBreakSelection() &&
            (canRequest ? (
              <BreakSelectionInput
                canSelectPreferredPosition={
                  selectedPackage?.canSelectPreferredPosition ?? false
                }
                preferredPositionSurcharge={
                  selectedPackage?.preferredPositionSurcharge ?? 0
                }
                handleBreakSelectionChange={handleBreakSelectionChange}
                medium={medium}
                subOrder={subOrder}
                productId={productId}
                selectionRequired
                isDirty={isDirty}
                alreadySelected={alreadySelected}
              />
            ) : (
              <Alert
                showIcon
                type="warning"
                message=""
                description={
                  <Trans>
                    Op dit moment kun je nog geen blokken koppelen, dit kan pas
                    zodra de periode is vrijgegeven door Ster.
                  </Trans>
                }
              />
            ))}

          {subOrder.period && (
            <DynamicIndexWarning
              medium={medium}
              className={styles.dynamicIndex}
              fixedCosts
              {...subOrder.period}
              {...subOrder}
            />
          )}
        </Form>
      </Spinner>
    );
  }
);

export default FixedCostsSubOrder;
