/* eslint-disable no-shadow */

export interface Period {
  from?: Date | null;
  to?: Date | null;
}

export type ClaimType =
  | "aanvragen_formulier_inter"
  | "aanvragen_formulier_radio"
  | "aanvragen_formulier_tv"
  | "analyse"
  | "bijlagen_download"
  | "bookspot"
  | "campagnes_inter"
  | "campagnes_radio"
  | "campagnes_tv"
  | "documenten_download"
  | "documenten_upload"
  | "documenten_upload_alles"
  | "facturen_inter"
  | "facturen_radio"
  | "facturen_tv"
  | "instructions_inter"
  | "instructions_radio"
  | "instructions_tv"
  | "material_inter"
  | "material_radio"
  | "material_tv"
  | "materialinzien_inter"
  | "materialinzien_radio"
  | "materialinzien_tv"
  | "toon_prijzen";

export type RoleType = "User" | "Medewerker" | "Admin" | "Intermediair";

export enum CommercialType {
  Audio = "audio",
  Video = "video",
  Banner = "banner",
}
