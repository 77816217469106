/* eslint-disable react/no-array-index-key */

import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form, Option, Select, Tag } from "@ster/ster-toolkit";
import { memo, useCallback } from "react";

import {
  DateSpan,
  Package,
  PackagePropertiesRadio,
  PackagePropertiesTv,
} from "../../../../api";
import { TagStatus, getPopupContainerSubOrders } from "../../../../utils";
import { PackageSelectRadioProps } from "./models";
import {
  getExtraPackageText,
  isPackageOptionDisabled,
} from "./PackageFunctions";
import styles from "./PackageSelectGrouped.module.less";

/**
 * Dropdown met pakketopties voor radio en tv
 */
const PackageSelectGrouped = memo(
  ({
    packages,
    groupedPackages,
    period,
    numberOfExcludedDays = 0,
    enabled,
    spreadEnabled,
    value,
    subOrder,
    subOrders,
  }: PackageSelectRadioProps) => {
    const { i18n } = useLingui();
    const isDisabled = useCallback(
      (selectedPackage: Package, { from, to }: DateSpan) =>
        isPackageOptionDisabled(
          selectedPackage,
          { from, to },
          numberOfExcludedDays,
          subOrders?.filter((s) => s.id !== subOrder?.id)
        ),
      [numberOfExcludedDays, subOrder?.id, subOrders]
    );

    const allPackagesInGroupSalesLocked = useCallback(
      (packageGroupName?: string) => {
        // Bepaal of alle pakketten binnen een groep een sales lock hebben
        const packagesInGroup = groupedPackages
          .find(({ name }) => name === packageGroupName)
          ?.properties.map((p) => packages.find(({ code }) => code === p.code));
        if (packagesInGroup == null || period == null) {
          return false;
        }
        const salesLocks = packagesInGroup.map((s) =>
          s ? isDisabled(s, period).salesLock : false
        );
        return salesLocks.every((s) => s === true);
      },
      [groupedPackages, isDisabled, packages, period]
    );

    const isSelectedPackageValid = useCallback(() => {
      const selectedPackage = packages.find(
        (s) => s.code === subOrder._package?.code
      );

      if (selectedPackage && period) {
        const { periodEnabled, yourTimeDisabled } = isDisabled(
          selectedPackage,
          period
        );
        return (
          periodEnabled &&
          !yourTimeDisabled &&
          !allPackagesInGroupSalesLocked(value) &&
          !(spreadEnabled && !selectedPackage.isSpreadEnabled)
        );
      }
      return true;
    }, [
      allPackagesInGroupSalesLocked,
      isDisabled,
      packages,
      period,
      spreadEnabled,
      subOrder._package?.code,
      value,
    ]);

    return (
      <Form.Item
        label={i18n._(t`Pakket`)}
        wrapperCol={{ span: 12 }}
        name="packageGroup"
        // ipv via de validator zetten we de validateStatus en het help attribuut zodat je meteen een melding krijgt als je pakket niet voldoet
        validateStatus={isSelectedPackageValid() ? "success" : "error"}
        help={
          isSelectedPackageValid()
            ? null
            : i18n._(t`Het pakket is niet beschikbaar in de gekozen periode.`)
        }
        rules={[
          {
            required: true,
            message: i18n._(t`Selecteer een pakket`),
          },
          {
            validator: (): Promise<void> =>
              isSelectedPackageValid()
                ? Promise.resolve()
                : Promise.reject(new Error()),
          },
        ]}
      >
        <Select.Search
          placeholder={i18n._(t`Selecteer een pakket`)}
          disabled={!enabled}
          getPopupContainer={getPopupContainerSubOrders}
        >
          {period &&
            groupedPackages.map(({ name, properties }, index) => {
              // Bepaal de eigenschappen van de groep op basis van het pakket met het laagste aantal minimaal dagen per week
              const packageMinDays = packages
                .filter(({ code }) => properties.some((s) => s.code === code))
                .reduce((prev, current) =>
                  prev && prev.minDays < current.minDays ? prev : current
                );
              const packageProperties = isDisabled(packageMinDays, period);
              const { periodEnabled, yourTimeDisabled } = packageProperties;

              // Als alle onderliggende pakketten een verkoopstop hebben is de optie niet meer te selecteren
              const allSalesLocked = allPackagesInGroupSalesLocked(name);
              packageProperties.salesLock = allSalesLocked;

              const optionEnabled =
                periodEnabled &&
                !yourTimeDisabled &&
                !allSalesLocked &&
                !(spreadEnabled && !packageMinDays.isSpreadEnabled);

              const propertyCodes: string[] = [];
              properties.forEach(
                (s: PackagePropertiesRadio | PackagePropertiesTv) =>
                  propertyCodes.push(s.code)
              );
              const allPackagesInGroup = packages.filter(({ code }) =>
                propertyCodes.includes(code)
              );

              return (
                <Option
                  key={`${name}:${index}`}
                  value={name}
                  disabled={!optionEnabled}
                  title={
                    !optionEnabled
                      ? i18n._(
                          t`Dit pakket is niet te kiezen, pas zonodig de periode van de deelorder aan`
                        )
                      : undefined
                  }
                >
                  {name}
                  {getExtraPackageText(
                    packageProperties,
                    spreadEnabled,
                    i18n,
                    packageMinDays
                  )}
                  <Tag
                    status={TagStatus.Info}
                    text={`${i18n._(t`Pakketindex`)}: ${
                      properties.length === 1
                        ? packageMinDays.productIndex
                        : `${Math.min(
                            ...allPackagesInGroup.map((s) => s.productIndex)
                          )} - ${Math.max(
                            ...allPackagesInGroup.map((s) => s.productIndex)
                          )}`
                    }`}
                    className={styles.dropdownTag}
                  />
                </Option>
              );
            })}
        </Select.Search>
      </Form.Item>
    );
  }
);

export default PackageSelectGrouped;
