import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form } from "@ster/ster-toolkit";
import { memo, useMemo } from "react";

import {
  GroupedPackageRadio,
  Package,
  PackagePropertiesRadio,
  PackageType,
} from "../../../../api";
import ChannelPicker from "./ChannelPicker";
import styles from "./PackageOptions.module.less";
import PackageSwitch from "./PackageSwitch";

const ChannelChoice = memo(
  ({
    groupedPackage: { properties: packagesProperties },
    selectedPackageProperties,
    selectedPackage,
    channels,
  }: {
    selectedPackageProperties?: PackagePropertiesRadio;
    groupedPackage: GroupedPackageRadio;
    selectedPackage?: Package;
    channels: string[];
  }) => {
    const { i18n } = useLingui();

    const isSeniors = useMemo(
      () => selectedPackage?.type === PackageType.Seniors,
      [selectedPackage?.type]
    );

    const isCombi50Plus = useMemo(
      () => selectedPackage?.type === PackageType.Combi50Plus,
      [selectedPackage?.type]
    );

    const label = useMemo(() => {
      if (isSeniors) {
        return i18n._(t`NPO Radio 1 uitsluiten`);
      }
      return i18n._(t`Zender uitsluiten`);
    }, [i18n, isSeniors]);

    const helpText = useMemo(() => {
      if (isSeniors) {
        return "";
      }
      if (isCombi50Plus) {
        return i18n._(
          t`Met deze optie kun je NPO Radio 1 of NPO Klassiek uitsluiten.`
        );
      }
      return i18n._(t`Met deze optie kun je 1 zender uitsluiten.`);
    }, [i18n, isCombi50Plus, isSeniors]);

    const showChannelPicker = useMemo(
      () =>
        selectedPackage?.hasChannelChoice && channels.length > 1 && !isSeniors,
      [selectedPackage, channels, isSeniors]
    );

    const disabledChannels: string[] = useMemo(
      () => (isCombi50Plus ? ["R5", "RR"] : []), // Radio 5 en Regio zijn niet uit te sluiten bij Combi 50+
      [isCombi50Plus]
    );

    const requiredDeselection = useMemo(() => {
      if (isCombi50Plus) {
        return 1; // Er mag maximaal 1 zender uitgesloten worden bij Combi 50+
      }
      return selectedPackageProperties?.hasCombi ? 1 : 0;
    }, [isCombi50Plus, selectedPackageProperties?.hasCombi]);

    const packagesPropertiesWithChannelChoice = useMemo(
      () =>
        packagesProperties.find(({ hasChannelChoice }) => hasChannelChoice) !==
        undefined,
      [packagesProperties]
    );

    return (
      <>
        {packagesPropertiesWithChannelChoice && (
          <Form.Item label={label} helpText={helpText}>
            <PackageSwitch
              name="hasChannelChoice"
              packagesProperties={packagesProperties}
              selectedPackageProperties={selectedPackageProperties}
            />
          </Form.Item>
        )}

        {showChannelPicker && (
          <Form.Item label={i18n._(t`Zenders`)} className={styles.channels}>
            <ChannelPicker
              channels={channels}
              disabledChannels={disabledChannels}
              requiredDeselection={requiredDeselection}
            />
          </Form.Item>
        )}
      </>
    );
  }
);

export default ChannelChoice;
