import "../styles.less";
import "./campaigncard.less";

import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  CollapseTillBreakpoint,
  Tag,
  Tooltip,
  Typography,
  formatToEuro,
} from "@ster/ster-toolkit";
import { Alert, Space } from "antd";
import classNames from "classnames";
import { useCallback, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  OrderListItem,
  OrderStatus,
  PortalUser,
  SettingsResult,
} from "../../../../api";
import { getOrderStatusTranslation, tagStatusMapper } from "../../../../utils";
import { getFormattedDate } from "../../../../utils/dateHelper";
import HistoryTooltip from "../../Tooltips/HistoryTooltip/HistoryTooltip";
import {
  useCampaignAnalysisDisabled,
  useCampaignAnalysisVisible,
} from "../CampaignDetail/CampaignAnalysisHooks";
import CampaignAnalysisText from "../CampaignDetail/CampaignAnalysisText";
import CardStatistic from "../CardStatistic";
import MediumIcon from "../MediumIcon";
import { formatDatePeriod } from "../utils";
import CampaignContextMenu from "./CampaignContextMenu";

interface CampaignCardProps {
  cardData: OrderListItem;
  displayCampaignAnalysisWhenAvailable: boolean;
  onDelete?: (order: OrderListItem) => void;
  openDetailsInNewTab?: boolean;
  settings?: SettingsResult;
  account?: PortalUser;
}

const CampaignCard = ({
  cardData,
  displayCampaignAnalysisWhenAvailable,
  onDelete,
  openDetailsInNewTab,
  settings,
  account,
}: CampaignCardProps) => {
  const {
    id,
    medium,
    name,
    advertiserName,
    productDescr,
    productId,
    startDate,
    endDate,
    orderStartDate,
    orderEndDate,
    budget,
    bookedBudget,
    status,
    needsInstructions,
    isProspect,
    disableDetails = false,
    analysis,
  } = cardData;

  const { i18n } = useLingui();
  const navigate = useNavigate();
  const { Title } = Typography;

  const formattedDatePeriod = formatDatePeriod(startDate, endDate);

  const formattedBudget = formatToEuro(budget, false);
  const formattedBookedBudget =
    bookedBudget && formatToEuro(bookedBudget, false);

  const orderLink =
    id > 0
      ? `/campaigns/${medium}/${id}`
      : `/campaigns/new/${medium}/${id}/${getFormattedDate(
          orderStartDate
        )}/${getFormattedDate(orderEndDate)}`;

  const goToAnalysis = useCallback(() => {
    navigate(`/campaigns/${medium}/${id}?analysis`);
  }, [id, medium, navigate]);

  const canEdit = useMemo(
    () =>
      account?.claims?.includes(`aanvragen_formulier_${medium.toLowerCase()}`),
    [account?.claims, medium]
  );

  const isCampaignAnalysisVisible = useCampaignAnalysisVisible(
    cardData,
    settings
  );
  const isCampaignAnalysisDisabled = useCampaignAnalysisDisabled(cardData);

  return (
    <article
      className={classNames(
        "card card--campaign",
        `card--campaign--status--${status.toLowerCase()}`
      )}
    >
      <header className="card-header">
        <div className="card-header__icon">
          <MediumIcon medium={medium} />
        </div>
        <div className="card-header__menu">
          {cardData.auditLogs.length > 0 && (
            <HistoryTooltip logs={cardData.auditLogs} />
          )}
          <Tag
            text={i18n._(getOrderStatusTranslation(status))}
            status={tagStatusMapper(status)}
          />
          <CampaignContextMenu
            order={cardData}
            showDelete={!!onDelete && status === OrderStatus.Concept && id < 0}
            onDelete={onDelete}
            settings={settings}
            canEdit={canEdit}
          />
        </div>
      </header>
      <div className="card-body">
        <Title level={2} className="card-title">
          {disableDetails ? (
            <Tooltip
              title={i18n._(
                t`Details worden pas inzichtelijk zodra de order bevestigd is.`
              )}
            >
              {name || productDescr}
            </Tooltip>
          ) : (
            <Link
              to={{
                pathname: orderLink,
              }}
              state={{ from: window.location.search }}
              className="card-title__link"
              title={i18n._(t`Ga naar campagne`)}
              target={openDetailsInNewTab ? "_blank" : ""}
            >
              {name || (isProspect ? i18n._(t`Nieuwe campagne`) : productDescr)}
            </Link>
          )}
        </Title>
        <span className={isProspect ? "" : "card-body__text"}>
          {advertiserName}
        </span>
        {!isProspect && (
          <>
            <span className="card-body__text">{productDescr}</span>{" "}
            <span className="card-body__text">({productId})</span>
          </>
        )}
        <footer>
          <div className="card-body__data-wrapper">
            <CardStatistic
              label={i18n._(t`Campagneperiode:`)}
              value={formattedDatePeriod}
            />
            <CollapseTillBreakpoint value={576}>
              <CardStatistic
                label={i18n._(t`Budget:`)}
                value={formattedBudget}
              />
              <CardStatistic
                label={i18n._(t`Geboekt:`)}
                value={formattedBookedBudget || 0}
              />
            </CollapseTillBreakpoint>
            {needsInstructions && (
              <span className="alert-wrapper">
                <Alert
                  type="warning"
                  message={i18n._(t`Uitzendinstructies nodig`)}
                  showIcon
                />
              </span>
            )}
          </div>
        </footer>
      </div>
      <Space className="card-footer">
        {analysis &&
          displayCampaignAnalysisWhenAvailable &&
          isCampaignAnalysisVisible && (
            <Button
              className="analysis-btn"
              disabled={isCampaignAnalysisDisabled}
              mode="secondary"
              onClick={goToAnalysis}
            >
              <CampaignAnalysisText analysis={analysis} />
            </Button>
          )}
      </Space>
    </article>
  );
};

export default CampaignCard;
