/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface PackagePropertiesRadio
 */
export interface PackagePropertiesRadio {
  /**
   *
   * @type {string}
   * @memberof PackagePropertiesRadio
   */
  code: string;
  /**
   *
   * @type {boolean}
   * @memberof PackagePropertiesRadio
   */
  hasCombi: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PackagePropertiesRadio
   */
  hasSpread: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PackagePropertiesRadio
   */
  hasChannelChoice: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PackagePropertiesRadio
   */
  hasHours: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PackagePropertiesRadio
   */
  hasDays: boolean;
}

export function PackagePropertiesRadioFromJSON(
  json: any
): PackagePropertiesRadio {
  return PackagePropertiesRadioFromJSONTyped(json, false);
}

export function PackagePropertiesRadioFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PackagePropertiesRadio {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    code: json["code"],
    hasCombi: json["hasCombi"],
    hasSpread: json["hasSpread"],
    hasChannelChoice: json["hasChannelChoice"],
    hasHours: json["hasHours"],
    hasDays: json["hasDays"],
  };
}

export function PackagePropertiesRadioToJSON(
  value?: PackagePropertiesRadio | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    code: value.code,
    hasCombi: value.hasCombi,
    hasSpread: value.hasSpread,
    hasChannelChoice: value.hasChannelChoice,
    hasHours: value.hasHours,
    hasDays: value.hasDays,
  };
}
