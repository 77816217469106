import { UndoOutlined } from "@ant-design/icons";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  Checkbox,
  ComponentLocalization,
  Form,
  Icons,
  Option,
  Radio,
  RangePicker,
  Select,
  Tag,
  formatToEuro,
} from "@ster/ster-toolkit";
import { Form as AntForm, Select as AntSelect } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { RadioChangeEvent } from "antd/lib/radio";
import { SelectValue } from "antd/lib/select";
import classNames from "classnames";
import { format } from "date-fns";
import { memo, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { MediumEnum } from "../../api/models";
import { StoreModel } from "../../store/models";
import { RangeValueType, getLanguage } from "../../utils";
import { defaultDateFormat } from "../../utils/dateHelper";
import styles from "./CampaignList.module.less";
import { CampaignListFilterProps } from "./models";
import { filterSelector } from "./selectors";

const CampaignFilters = memo(
  ({
    data,
    data: { filters },
    availableMediumTypes,
    filtersDisabled,
    showEditableFilter,
  }: CampaignListFilterProps): React.ReactElement => {
    const { i18n } = useLingui();

    // Producten en statussen op dit niveau bepalen aangezien deze afhankelijk zijn van de andere filters
    const { products, statusses } = useSelector((state: StoreModel) =>
      filterSelector(state, data)
    );
    const [form] = AntForm.useForm();
    const navigate = useNavigate();
    const location = useLocation();
    const updateQueryString = useCallback(
      (state: Record<string, string | undefined>) => {
        const queryParams = new URLSearchParams(location.search);
        const keys = Object.keys(state);
        for (const key of keys) {
          const value = state[key];
          if (queryParams.has(key)) {
            queryParams.delete(key);
          }

          if (value) {
            queryParams.append(key, value);
          }
        }

        navigate({ ...location, search: queryParams.toString() });
      },
      [location, navigate]
    );

    useEffect((): void => {
      form.setFieldsValue({
        period: [filters.date.selected.from, filters.date.selected.to],
        editable: filters.editable,
        email: `${filters.email.value}`,
        medium: filters.medium.selected,
        products: !filters.products.selected ? "" : filters.products.selected,
        advertisers: !filters.advertisers.selected
          ? ""
          : filters.advertisers.selected,
      });

      // Status
      if (!filters.statusses.selected) {
        form.resetFields(["status"]);
      } else {
        form.setFieldsValue({
          status: filters.statusses.selected,
        });
      }
    }, [form, filters]);

    const changeEmail = useCallback(
      ({ target }: RadioChangeEvent) => {
        updateQueryString({ email: target.value });
      },
      [updateQueryString]
    );

    const changeMedium = useCallback(
      ({ target }: RadioChangeEvent) => {
        updateQueryString({ medium: target.value });
      },
      [updateQueryString]
    );

    const changeAdvertiser = useCallback(
      (value: SelectValue): void => {
        // Reset het product bij het wijzigen van adverteerder
        updateQueryString({ advertiser: value as string, product: "" });
      },
      [updateQueryString]
    );

    const changeProduct = useCallback(
      (value: SelectValue): void => {
        updateQueryString({ product: value as string });
      },
      [updateQueryString]
    );

    const changeStatusAll = useCallback((): void => {
      updateQueryString({ status: undefined });
    }, [updateQueryString]);

    const changeEditable = useCallback(
      ({ target }: CheckboxChangeEvent): void => {
        updateQueryString({
          editable: target.checked ? "true" : undefined,
        });
      },
      [updateQueryString]
    );

    const changeStatus = useCallback(
      (checkedValue: CheckboxValueType[]): void => {
        updateQueryString({
          status: checkedValue.map((value) => value as string).join(","),
        });
      },
      [updateQueryString]
    );

    const updatePeriod = useCallback(
      (open: boolean) => {
        // Als de datumkiezer sluit updaten we de periode
        if (!open) {
          const periodValue = form.getFieldValue(
            "period"
          ) as RangeValueType<Date> | null;
          const dateFrom = periodValue?.[0];
          const dateTo = periodValue?.[1];
          if (dateFrom && dateTo) {
            updateQueryString({
              from: format(dateFrom, defaultDateFormat),
              to: format(dateTo, defaultDateFormat),
            });
          } else {
            updateQueryString({ from: undefined, to: undefined });
          }
        }
      },
      [form, updateQueryString]
    );

    const clearFilters = useCallback(() => {
      navigate({ ...location, search: undefined });
    }, [location, navigate]);

    return (
      <Form className={styles.filterContainer} form={form}>
        {location.search !== "" && (
          <Button
            icon={<UndoOutlined />}
            mode="tertiary"
            type="link"
            className={styles.reset}
            onClick={clearFilters}
          >
            {i18n._(t`Filters resetten`)}
          </Button>
        )}
        {data.filters.email.show && (
          <Form.Item name="email" label={i18n._(t`Campagnes`)}>
            <Radio.Group
              name="radioEmail"
              onChange={changeEmail}
              disabled={filtersDisabled}
            >
              <Radio value="true">
                <Trans>Eigen</Trans>
              </Radio>
              <Radio value="false">
                <Trans>Alle</Trans>
              </Radio>
            </Radio.Group>
          </Form.Item>
        )}
        <Form.Item name="medium" label={i18n._(t`Mediumtype`)}>
          <Radio.Group
            mode="vertical"
            name="radioMediaGroup"
            onChange={changeMedium}
            disabled={filtersDisabled}
          >
            {availableMediumTypes.length > 1 && (
              <Radio value="">
                <Trans>Alle</Trans>
              </Radio>
            )}
            {availableMediumTypes.includes(MediumEnum.Tv) && (
              <Radio value="tv">
                <Trans>Televisie</Trans>
                <Icons.TVIcon width={20} height={20} />
              </Radio>
            )}
            {availableMediumTypes.includes(MediumEnum.Radio) && (
              <Radio value="radio">
                <Trans>Radio</Trans>
                <Icons.RadioIcon width={20} height={20} />
              </Radio>
            )}
            {availableMediumTypes.includes(MediumEnum.Inter) && (
              <Radio value="inter">
                <Trans>Online</Trans>
                <Icons.OnlineIcon width={20} height={20} />
              </Radio>
            )}
          </Radio.Group>
        </Form.Item>

        <Form.Item name="period" label={i18n._(t`Periode`)}>
          <RangePicker
            allowClear={false}
            onOpenChange={updatePeriod}
            disabled={filtersDisabled}
            componentLocale={getLanguage() as ComponentLocalization}
          />
        </Form.Item>

        <Form.Item
          name="advertisers"
          className={styles.advertisers}
          label={i18n._(t`Adverteerder`)}
        >
          <Select.Search
            onChange={changeAdvertiser}
            optionFilterProp="children"
            disabled={filtersDisabled}
          >
            <Option value="" key={0}>
              <Trans>Alle adverteerders</Trans>
            </Option>
            {filters.advertisers.extra?.length ? (
              <>
                <AntSelect.OptGroup label={i18n._(t`Nieuwe Adverteerders`)}>
                  {filters.advertisers.extra?.map((advertiser) => (
                    <Option value={advertiser.id} key={advertiser.id}>
                      {advertiser.name}
                    </Option>
                  ))}
                </AntSelect.OptGroup>
                {!!filters.advertisers.items?.length && (
                  <AntSelect.OptGroup label={i18n._(t`Adverteerders`)}>
                    {filters.advertisers.items?.map((advertiser) => (
                      <Option value={advertiser.id} key={advertiser.id}>
                        {advertiser.name}
                      </Option>
                    ))}
                  </AntSelect.OptGroup>
                )}
              </>
            ) : (
              filters.advertisers.items?.map((advertiser) => (
                <Option value={advertiser.id} key={advertiser.id}>
                  {advertiser.name}
                </Option>
              ))
            )}
          </Select.Search>
        </Form.Item>
        <Form.Item name="products">
          <Select.Search
            onChange={changeProduct}
            optionFilterProp="children"
            disabled={filtersDisabled}
          >
            <Option value="" key={0}>
              <Trans>Alle sternummers</Trans>
            </Option>
            {products.map((product) => (
              <Option value={product.id || 0} key={product.id}>
                {product.id} - {product.description}
              </Option>
            ))}
          </Select.Search>
        </Form.Item>

        <Form.Item label={i18n._(t`Status`)} className={styles.status}>
          <Checkbox
            key="Alle statussen"
            onChange={changeStatusAll}
            value=""
            checked={filters.statusses.allSelected}
            defaultChecked
            disabled={filtersDisabled}
            className={classNames("checkbox", styles.allStatus)}
          >
            <Trans>Alle statussen</Trans>
            <span>
              {formatToEuro(
                statusses.reduce((sum, filter) => sum + filter.budget, 0),
                false
              )}
            </span>
          </Checkbox>
          <Checkbox.Group
            mode="vertical"
            value={filters.statusses.selected}
            onChange={changeStatus}
            disabled={filtersDisabled}
          >
            {statusses.map((status) => (
              <Checkbox value={status.value} key={i18n._(status.name)}>
                <Tag text={i18n._(status.name)} status={status.tagStatus} />
                <span>{formatToEuro(status.budget, false)}</span>
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Form.Item>

        {showEditableFilter && (
          <Form.Item
            label={i18n._(t`Wijzigen`)}
            className={styles.status}
            name="editable"
            valuePropName="checked"
          >
            <Checkbox
              onChange={changeEditable}
              disabled={filtersDisabled}
              className={classNames("checkbox", styles.allStatus)}
            >
              <Trans>Nog te wijzigen</Trans>
            </Checkbox>
          </Form.Item>
        )}
      </Form>
    );
  }
);

export default CampaignFilters;
