/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  Advertiser,
  AdvertiserFromJSON,
  AdvertiserToJSON,
  DeactivateUserInput,
  DeactivateUserInputFromJSON,
  DeactivateUserInputToJSON,
  IdentityResult,
  IdentityResultFromJSON,
  IdentityResultToJSON,
  Organisation,
  OrganisationFromJSON,
  OrganisationToJSON,
  PortalUser,
  PortalUserFromJSON,
  PortalUserToJSON,
  ProblemDetails,
  ProblemDetailsFromJSON,
  ProblemDetailsToJSON,
  ResultActivated,
  ResultActivatedFromJSON,
  ResultActivatedToJSON,
  ResultDeleted,
  ResultDeletedFromJSON,
  ResultDeletedToJSON,
  UserModel,
  UserModelFromJSON,
  UserModelToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface ApiV1AccountActivateUserIdPostRequest {
  userId: string;
}

export interface ApiV1AccountDeactivateDeleteRequest {
  deactivateUserInput: DeactivateUserInput;
}

export interface ApiV1AccountDeleteUserIdDeleteRequest {
  userId: string;
}

export interface ApiV1AccountDeleteUserIdPostRequest {
  userId: string;
}

export interface ApiV1AccountRegisterPostRequest {
  userModel?: UserModel;
}

export interface ApiV1AccountUpdateUserIdPostRequest {
  userId: string;
  userModel?: UserModel;
}

export interface ApiV1AccountUserIdGetRequest {
  userId: string;
}

/**
 *
 */
export class AccountApi extends runtime.BaseAPI {
  /**
   */
  async apiV1AccountActivateUserIdPostRaw(
    requestParameters: ApiV1AccountActivateUserIdPostRequest
  ): Promise<runtime.ApiResponse<ResultActivated>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        "userId",
        "Required parameter requestParameters.userId was null or undefined when calling apiV1AccountActivateUserIdPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Account/activate/{userId}`.replace(
        `{${"userId"}}`,
        encodeURIComponent(String(requestParameters.userId))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResultActivatedFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1AccountActivateUserIdPost(
    requestParameters: ApiV1AccountActivateUserIdPostRequest
  ): Promise<ResultActivated> {
    const response =
      await this.apiV1AccountActivateUserIdPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1AccountAdvertisersGetRaw(): Promise<
    runtime.ApiResponse<Array<Advertiser>>
  > {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Account/advertisers`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(AdvertiserFromJSON)
    );
  }

  /**
   */
  async apiV1AccountAdvertisersGet(): Promise<Array<Advertiser>> {
    const response = await this.apiV1AccountAdvertisersGetRaw();
    return await response.value();
  }

  /**
   */
  async apiV1AccountAllGetRaw(): Promise<
    runtime.ApiResponse<Array<PortalUser>>
  > {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Account/all`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(PortalUserFromJSON)
    );
  }

  /**
   */
  async apiV1AccountAllGet(): Promise<Array<PortalUser>> {
    const response = await this.apiV1AccountAllGetRaw();
    return await response.value();
  }

  /**
   */
  async apiV1AccountDeactivateDeleteRaw(
    requestParameters: ApiV1AccountDeactivateDeleteRequest
  ): Promise<runtime.ApiResponse<ResultDeleted>> {
    if (
      requestParameters.deactivateUserInput === null ||
      requestParameters.deactivateUserInput === undefined
    ) {
      throw new runtime.RequiredError(
        "deactivateUserInput",
        "Required parameter requestParameters.deactivateUserInput was null or undefined when calling apiV1AccountDeactivateDelete."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Account/deactivate`,
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
      body: DeactivateUserInputToJSON(requestParameters.deactivateUserInput),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResultDeletedFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1AccountDeactivateDelete(
    requestParameters: ApiV1AccountDeactivateDeleteRequest
  ): Promise<ResultDeleted> {
    const response =
      await this.apiV1AccountDeactivateDeleteRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1AccountDeleteUserIdDeleteRaw(
    requestParameters: ApiV1AccountDeleteUserIdDeleteRequest
  ): Promise<runtime.ApiResponse<IdentityResult>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        "userId",
        "Required parameter requestParameters.userId was null or undefined when calling apiV1AccountDeleteUserIdDelete."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Account/delete/{userId}`.replace(
        `{${"userId"}}`,
        encodeURIComponent(String(requestParameters.userId))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      IdentityResultFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1AccountDeleteUserIdDelete(
    requestParameters: ApiV1AccountDeleteUserIdDeleteRequest
  ): Promise<IdentityResult> {
    const response =
      await this.apiV1AccountDeleteUserIdDeleteRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1AccountDeleteUserIdPostRaw(
    requestParameters: ApiV1AccountDeleteUserIdPostRequest
  ): Promise<runtime.ApiResponse<ResultDeleted>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        "userId",
        "Required parameter requestParameters.userId was null or undefined when calling apiV1AccountDeleteUserIdPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Account/delete/{userId}`.replace(
        `{${"userId"}}`,
        encodeURIComponent(String(requestParameters.userId))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResultDeletedFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1AccountDeleteUserIdPost(
    requestParameters: ApiV1AccountDeleteUserIdPostRequest
  ): Promise<ResultDeleted> {
    const response =
      await this.apiV1AccountDeleteUserIdPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1AccountExportGetRaw(): Promise<runtime.ApiResponse<Blob>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Account/export`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async apiV1AccountExportGet(): Promise<Blob> {
    const response = await this.apiV1AccountExportGetRaw();
    return await response.value();
  }

  /**
   */
  async apiV1AccountMeGetRaw(): Promise<runtime.ApiResponse<PortalUser>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Account/me`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PortalUserFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1AccountMeGet(): Promise<PortalUser> {
    const response = await this.apiV1AccountMeGetRaw();
    return await response.value();
  }

  /**
   */
  async apiV1AccountOrganisationsGetRaw(): Promise<
    runtime.ApiResponse<Array<Organisation>>
  > {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Account/organisations`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(OrganisationFromJSON)
    );
  }

  /**
   */
  async apiV1AccountOrganisationsGet(): Promise<Array<Organisation>> {
    const response = await this.apiV1AccountOrganisationsGetRaw();
    return await response.value();
  }

  /**
   */
  async apiV1AccountRegisterPostRaw(
    requestParameters: ApiV1AccountRegisterPostRequest
  ): Promise<runtime.ApiResponse<PortalUser>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Account/register`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: UserModelToJSON(requestParameters.userModel),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PortalUserFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1AccountRegisterPost(
    requestParameters: ApiV1AccountRegisterPostRequest
  ): Promise<PortalUser> {
    const response = await this.apiV1AccountRegisterPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1AccountUpdateUserIdPostRaw(
    requestParameters: ApiV1AccountUpdateUserIdPostRequest
  ): Promise<runtime.ApiResponse<PortalUser>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        "userId",
        "Required parameter requestParameters.userId was null or undefined when calling apiV1AccountUpdateUserIdPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Account/update/{userId}`.replace(
        `{${"userId"}}`,
        encodeURIComponent(String(requestParameters.userId))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: UserModelToJSON(requestParameters.userModel),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PortalUserFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1AccountUpdateUserIdPost(
    requestParameters: ApiV1AccountUpdateUserIdPostRequest
  ): Promise<PortalUser> {
    const response =
      await this.apiV1AccountUpdateUserIdPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1AccountUserIdGetRaw(
    requestParameters: ApiV1AccountUserIdGetRequest
  ): Promise<runtime.ApiResponse<PortalUser>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        "userId",
        "Required parameter requestParameters.userId was null or undefined when calling apiV1AccountUserIdGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Account/{userId}`.replace(
        `{${"userId"}}`,
        encodeURIComponent(String(requestParameters.userId))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PortalUserFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1AccountUserIdGet(
    requestParameters: ApiV1AccountUserIdGetRequest
  ): Promise<PortalUser> {
    const response = await this.apiV1AccountUserIdGetRaw(requestParameters);
    return await response.value();
  }
}
