import { AnyAction } from "redux";
import { Reducer, createReducer } from "typesafe-actions";

import { SettingsResult } from "../../api/models";
import { ReduxStoreState } from "../base";
import { LoadingSettings } from "../models";
import { receiveSettingsAction } from "./actions";

// eslint-disable-next-line import/prefer-default-export
export const settingsReducer: Reducer<LoadingSettings, AnyAction> =
  createReducer({
    state: ReduxStoreState.Initial,
  })
    .handleAction(receiveSettingsAction.request, (state: SettingsResult) => ({
      ...state,
      loading: true,
      state: ReduxStoreState.Loading,
    }))
    .handleAction(receiveSettingsAction.failure, (state: SettingsResult) => ({
      ...state,
      loading: false,
      state: ReduxStoreState.Failure,
    }))
    .handleAction(
      receiveSettingsAction.success,
      (
        state: SettingsResult,
        action: ReturnType<typeof receiveSettingsAction.success>
      ) => ({
        ...action.payload,
        loading: false,
        state: ReduxStoreState.Success,
      })
    );
