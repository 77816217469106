import { AnyAction } from "redux";
import { Reducer, createReducer } from "typesafe-actions";

import { SettingsResult } from "../../api/models";
import { ReduxStoreState } from "../base";
import { LoadingSecondaryTargetGroups } from "../models";
import { receiveSecondaryTargetGroupsAction } from "./actions";

// eslint-disable-next-line import/prefer-default-export
export const secondaryTargetGroupsReducer: Reducer<
  LoadingSecondaryTargetGroups,
  AnyAction
> = createReducer({
  state: ReduxStoreState.Initial,
})
  .handleAction(
    receiveSecondaryTargetGroupsAction.request,
    (state: SettingsResult) => ({
      ...state,
      loading: true,
      state: ReduxStoreState.Loading,
    })
  )
  .handleAction(
    receiveSecondaryTargetGroupsAction.failure,
    (state: SettingsResult) => ({
      ...state,
      loading: false,
      state: ReduxStoreState.Failure,
    })
  )
  .handleAction(
    receiveSecondaryTargetGroupsAction.success,
    (
      state: SettingsResult,
      action: ReturnType<typeof receiveSecondaryTargetGroupsAction.success>
    ) => ({
      ...action.payload,
      loading: false,
      state: ReduxStoreState.Success,
    })
  );
