import { createAsyncAction } from "typesafe-actions";

import { ApiV1ForecastInitialRequestsPostRequest } from "../../api";
import { ForecastResult } from "../../api/models";
import { ResponseError } from "../base";
import { actionNameAsync } from "../utils";

// eslint-disable-next-line import/prefer-default-export
export const receiveForecastsForProposalAction = createAsyncAction(
  actionNameAsync("forecastsForProposal", "REQUEST"),
  actionNameAsync("forecastsForProposal", "SUCCESS"),
  actionNameAsync("forecastsForProposal", "FAILURE")
)<
  ApiV1ForecastInitialRequestsPostRequest,
  Record<string, ForecastResult>,
  ResponseError
>();
