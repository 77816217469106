import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  Form,
  Modal,
  Option,
  Select,
  Typography,
} from "@ster/ster-toolkit";
import { Alert, Form as AntForm, Space } from "antd";
import { Store } from "antd/lib/form/interface";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { MediumEnum } from "../../../api";
import { ReduxStoreState } from "../../../store/base";
import {
  receiveContactsForForwardAction,
  receiveProductsForForwardAction,
} from "../../../store/campaignForward/actions";
import { StoreModel } from "../../../store/models";
import { receiveOrganisationsAction } from "../../../store/organisations/actions";
import { errorMessageMapping } from "../../errorMessages";

const CampaignForwardModal = ({
  onClose,
  medium,
  dateFrom,
  dateTo,
  loading,
  error,
  saving,
}: {
  onClose: () => void;
  medium: MediumEnum;
  dateFrom: Date;
  dateTo: Date;
  loading?: boolean;
  error?: string | null;
  saving: boolean;
}) => {
  const { i18n } = useLingui();
  const [form] = AntForm.useForm();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(receiveOrganisationsAction.request());
  }, [dispatch]);

  const { organisations = [], loading: loadingOrganisations = false } =
    useSelector((store: StoreModel) => store.organisations);

  const handleChanges = useCallback(
    (changedValues: Store, _values: Store): void => {
      if (changedValues.organisationCode) {
        dispatch(
          receiveContactsForForwardAction.request({
            organisationCode: changedValues.organisationCode,
          })
        );
        dispatch(
          receiveProductsForForwardAction.request({
            medium,
            dateFrom,
            dateTo,
            organisationCode: changedValues.organisationCode,
          })
        );
      }
    },
    [dateFrom, dateTo, dispatch, medium]
  );

  const {
    advertisers = [],
    loading: loadingAdvertisers = false,
    state: stateAdvertisers,
  } = useSelector((store: StoreModel) => store.campaignForward.products);

  const advertiserId = AntForm.useWatch("advertiserId", form);
  useEffect(() => {
    if (stateAdvertisers !== ReduxStoreState.Success) {
      form.setFieldsValue({ advertiserId: undefined, productId: undefined });
      return;
    }

    if (advertiserId) {
      const products = advertisers.find((a) => a.id === advertiserId)?.products;
      if (products?.length === 1) {
        // if only one product, select it
        form.setFieldsValue({ productId: products?.[0].id });
      } else if (Number(products?.length) > 0) {
        // reset `productId` value
        form.setFieldsValue({ productId: undefined });
      }
    } else if (advertisers.length === 1) {
      form.setFieldsValue({ advertiserId: advertisers?.[0].id });
    } else {
      form.setFieldsValue({ advertiserId: undefined });
    }
  }, [advertiserId, advertisers, form, stateAdvertisers]);

  const {
    contacts = [],
    loading: loadingContacts = false,
    state: stateContacts,
  } = useSelector((store: StoreModel) => store.campaignForward.contacts);

  const contactEmail = AntForm.useWatch("email", form);
  useEffect(() => {
    if (stateContacts !== ReduxStoreState.Success) {
      form.setFieldsValue({ email: undefined });
      return;
    }

    if (contactEmail) {
      return;
    }

    if (contacts.length === 1) {
      form.setFieldsValue({ email: contacts?.[0].email });
    } else {
      form.setFieldsValue({ email: undefined });
    }
  }, [contactEmail, contacts, form, stateContacts]);

  return (
    <Modal
      open
      footer={null}
      title={i18n._(t`Campagne doorsturen`)}
      width={640}
      onCancel={onClose}
    >
      <Typography.Paragraph>
        <Trans>
          Met behulp van onderstaand formulier is het mogelijk het huidige
          concept door te sturen naar een contactpersoon bij een andere
          organisatie.
        </Trans>
      </Typography.Paragraph>

      {error && (
        <Alert
          showIcon
          type="error"
          message=""
          description={i18n._(errorMessageMapping[error])}
          style={{ marginBottom: 16 }}
        />
      )}

      <Form
        name="forward"
        onValuesChange={handleChanges}
        autoComplete="off"
        form={form}
      >
        <Form.Item
          name="organisationCode"
          label={i18n._(t`Organisatie`)}
          rules={[{ required: true, message: i18n._(t`Kies een organisatie`) }]}
        >
          <Select.Search
            placeholder={i18n._(t`Zoek`)}
            optionFilterProp="search"
            disabled={loadingOrganisations}
            loading={loadingOrganisations}
          >
            {organisations.map(({ code, name }) => (
              <Option
                value={code ?? ""}
                key={code ?? ""}
                search={`${name} ${code}`}
              >
                {name} ({code})
              </Option>
            ))}
          </Select.Search>
        </Form.Item>

        <Form.Item
          label={i18n._(t`Contactpersoon`)}
          dependencies={["organisationCode"]}
        >
          {({ getFieldValue }) => {
            const noContacts =
              contacts.length === 0 &&
              stateContacts !== ReduxStoreState.Initial &&
              stateContacts !== ReduxStoreState.Loading;

            return (
              <>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: i18n._(t`Kies een contactpersoon`),
                    },
                  ]}
                  noStyle
                >
                  <Select.Search
                    placeholder={i18n._(t`Zoek`)}
                    optionFilterProp="search"
                    disabled={
                      loadingContacts ||
                      !getFieldValue("organisationCode") ||
                      contacts.length === 0
                    }
                    loading={loadingContacts}
                  >
                    {contacts.map(({ email, fullName }) => (
                      <Option
                        value={email}
                        key={email}
                        search={`${fullName} ${email}`}
                      >
                        {fullName}
                      </Option>
                    ))}
                  </Select.Search>
                </Form.Item>
                {noContacts && (
                  <Typography.Text type="danger">
                    Geen contactpersonen gevonden in gekozen organisatie.
                  </Typography.Text>
                )}
              </>
            );
          }}
        </Form.Item>

        <Form.Item
          label={i18n._(t`Adverteerder`)}
          dependencies={["organisationCode"]}
        >
          {({ getFieldValue }) => {
            const noAdvertisers =
              advertisers.length === 0 &&
              stateAdvertisers !== ReduxStoreState.Initial &&
              stateAdvertisers !== ReduxStoreState.Loading;

            return (
              <>
                <Form.Item
                  name="advertiserId"
                  rules={[
                    {
                      required: true,
                      message: i18n._(t`Kies een adverteerder`),
                    },
                  ]}
                  noStyle
                >
                  <Select.Search
                    placeholder={i18n._(t`Zoek`)}
                    optionFilterProp="search"
                    disabled={
                      loadingAdvertisers ||
                      !getFieldValue("organisationCode") ||
                      advertisers.length === 0
                    }
                    loading={loadingAdvertisers}
                  >
                    {advertisers.map(({ id, name }) => (
                      <Option value={id} key={id} search={`${name} ${id}`}>
                        {name}
                      </Option>
                    ))}
                  </Select.Search>
                </Form.Item>
                {noAdvertisers && (
                  <Typography.Text type="danger">
                    Geen adverteerders gevonden in gekozen organisatie.
                  </Typography.Text>
                )}
              </>
            );
          }}
        </Form.Item>

        <Form.Item
          label={i18n._(t`Sternummer`)}
          dependencies={["organisationCode", "advertiserId"]}
        >
          {({ getFieldValue }) => {
            const currentAdvertiser = advertisers.find(
              (a) => a.id === getFieldValue("advertiserId")
            );
            const products = currentAdvertiser?.products ?? [];

            return (
              <Form.Item
                name="productId"
                rules={[
                  { required: true, message: i18n._(t`Kies een sternummer`) },
                ]}
                noStyle
              >
                <Select.Search
                  placeholder={i18n._(t`Zoek`)}
                  optionFilterProp="search"
                  disabled={
                    loadingAdvertisers ||
                    !getFieldValue("organisationCode") ||
                    advertisers.length === 0
                  }
                  loading={loadingAdvertisers}
                >
                  {products.map(({ id, description }) => (
                    <Option value={id} key={id} search={`${description} ${id}`}>
                      {description}
                    </Option>
                  ))}
                </Select.Search>
              </Form.Item>
            );
          }}
        </Form.Item>

        <Form.Item>
          <Space
            style={{ width: "100%", justifyContent: "flex-end" }}
            align="end"
          >
            <Button
              mode="tertiary"
              onClick={onClose}
              disabled={loading || saving}
            >
              <Trans>Annuleren</Trans>
            </Button>
            <Button
              mode="primary"
              htmlType="submit"
              loading={loading || saving}
              disabled={loading || saving}
            >
              <Trans>Versturen</Trans>
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CampaignForwardModal;
