import { AnyAction } from "redux";
import { Reducer, createReducer } from "typesafe-actions";

import { ReduxStoreState } from "../base";
import { LoadingOrganisations } from "../models";
import { receiveOrganisationsAction } from "./actions";

// eslint-disable-next-line import/prefer-default-export
export const organisationsReducer: Reducer<LoadingOrganisations, AnyAction> =
  createReducer({
    state: ReduxStoreState.Initial,
  })
    .handleAction(
      receiveOrganisationsAction.request,
      (state: LoadingOrganisations) => ({
        ...state,
        loading: true,
        state: ReduxStoreState.Loading,
      })
    )
    .handleAction(
      receiveOrganisationsAction.failure,
      (state: LoadingOrganisations) => ({
        ...state,
        loading: false,
        state: ReduxStoreState.Failure,
      })
    )
    .handleAction(
      receiveOrganisationsAction.success,
      (
        state: LoadingOrganisations,
        action: ReturnType<typeof receiveOrganisationsAction.success>
      ) => ({
        organisations: action.payload || [],
        loading: false,
        state: ReduxStoreState.Success,
      })
    );
