import { createAsyncAction } from "typesafe-actions";

import { Advertiser } from "../../api/models";
import { ResponseError } from "../base";
import { actionNameAsync } from "../utils";

// eslint-disable-next-line import/prefer-default-export
export const receiveAdvertisersAction = createAsyncAction(
  actionNameAsync("advertisers", "REQUEST"),
  actionNameAsync("advertisers", "SUCCESS"),
  actionNameAsync("advertisers", "FAILURE")
)<undefined, Advertiser[], ResponseError>();
