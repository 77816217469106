import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useDispatch, useSelector } from "react-redux";

import { TenantEnum } from "../api";
import {
  availableTenantsSelector,
  tenantContextSelector,
} from "../shared/selectors";
import { setCurrentTenantAction } from "../store/account/actions";

const tenantTranslations = {
  [TenantEnum.National]: t`Nationaal`,
  [TenantEnum.Regional]: t`Regionaal`,
};

const TenantSwitch = () => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();

  const availableTenants = useSelector(availableTenantsSelector);
  const tenantContext = useSelector(tenantContextSelector);
  const tenantsToSwitchTo = availableTenants?.filter(
    (tenant) => tenant !== tenantContext.currentTenant
  );

  const handleTenantSwitchClick = (tenant: TenantEnum) => {
    dispatch(setCurrentTenantAction(tenant));
  };

  return tenantsToSwitchTo?.map((tenant) => (
    <button
      type="button"
      className="link context-menu__link"
      onClick={() => handleTenantSwitchClick(tenant)}
    >
      {i18n._(t`Switch naar `)} {i18n._(tenantTranslations[tenant])}
    </button>
  ));
};

export default TenantSwitch;
