import { createSelector, createStructuredSelector } from "reselect";

import {
  MediumEnum,
  OrderRequest,
  OrderStatus,
  SalesPeriod,
  SalesPeriodResult,
  SettingsResult,
} from "../../api";
import { salesPeriodSelector } from "../../shared/selectors";
import { Loading } from "../../store/base";
import {
  LoadingEditableCampaign,
  LoadingPortalUser,
  StoreModel,
} from "../../store/models";

export const editableCampaignSelector = (
  { editableCampaigns }: StoreModel,
  orderId: number
): LoadingEditableCampaign | undefined => editableCampaigns[orderId];

export const updateCampaignSelector = ({
  updateCampaign,
}: StoreModel): Loading | undefined => updateCampaign;

export const campaignSelector = createSelector(
  [editableCampaignSelector],
  (
    editableCampaign: LoadingEditableCampaign | undefined
  ): OrderRequest | undefined => editableCampaign?.campaign
);

const loadingEditSelector = createSelector(
  [editableCampaignSelector],
  (editableCampaign: LoadingEditableCampaign | undefined): boolean =>
    editableCampaign?.loading ?? false
);

const loadingUpdateSelector = createSelector(
  [updateCampaignSelector],
  (updateCampaign: Loading | undefined | null): boolean =>
    updateCampaign?.loading ?? false
);

export const accountSelector = ({ account }: StoreModel): LoadingPortalUser =>
  account;

export const settingsSelector = ({ settings }: StoreModel): SettingsResult =>
  settings;

const selectedSalesPeriodSelector = createSelector(
  [salesPeriodSelector, campaignSelector],
  (
    salesPeriods: SalesPeriodResult[],
    order: OrderRequest | undefined
  ): SalesPeriod | undefined => {
    if (!order?.period) {
      return undefined;
    }

    // Voor online campagnes moet de periode van de order binnen die van de aanvraagperiode liggen
    // Voor radio en TV moet deze deels overlappen ivm eventuele maandoverschrijding
    if (order.medium === MediumEnum.Inter) {
      const periods = salesPeriods
        .find((s) => s.medium === order.medium)
        ?.result.find(
          (s) =>
            s.salesPeriodStartDate <= order.period.from &&
            s.salesPeriodEndDate >= order.period.to
        );

      // Bij het wijzigen van een bestaande campagne kan het zijn dat de huidige verkoopperiode na de start van de campagne ligt
      // Dan zoeken we de eerste periode waarbij de einddatum na die van de campagne ligt
      if (!periods && order.requestStatus !== OrderStatus.Concept) {
        return salesPeriods
          .find((s) => s.medium === order.medium)
          ?.result.find((s) => s.salesPeriodEndDate >= order.period.to);
      }
      return periods;
    }

    return salesPeriods
      .find((s) => s.medium === order.medium)
      ?.result.find(
        (s) =>
          s.salesPeriodStartDate <= order.period.to &&
          s.salesPeriodEndDate >= order.period.from
      );
  }
);

export const campaignEditRootSelector = createStructuredSelector({
  campaign: campaignSelector,
  loading: loadingEditSelector,
  settings: settingsSelector,
  selectedSalesPeriod: selectedSalesPeriodSelector,
});

export const updateModalSelector = createStructuredSelector({
  account: accountSelector,
  loadingRequest: loadingUpdateSelector,
});
