import { i18n } from "@lingui/core";
import { Trans, t } from "@lingui/macro";
import {
  Button,
  ContentContainer,
  Icons,
  Pageheader,
  Row,
  Spinner,
  Typography,
} from "@ster/ster-toolkit";
import { Col } from "antd";
import { memo, useCallback, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { MediumEnum } from "../../api";
import { receiveCampaignsAction } from "../../store/campaigns/actions";
import { StoreModel } from "../../store/models";
import { receiveForecastsForProposalAction } from "../../store/proposal/actions";
import Notification from "../Notification";
import CampaignBackButton from "../partials/CampaignBackButton";
import Forecast from "../partials/Forecast/Forecast";
import ProposalCampaign from "./ProposalCampaign";
import ProposalRequest from "./ProposalRequest";
import { proposalRootSelector } from "./selectors";

const ProposalWithForecast = memo(() => {
  const {
    medium: mediumFromParams,
    ids: idsFromParams,
    from: fromFromParams,
    to: toFromParams,
  } = useParams<{
    medium?: string;
    ids?: string;
    from?: string;
    to?: string;
  }>();

  const medium = mediumFromParams as MediumEnum;
  const from = useMemo(
    () => new Date(fromFromParams as string),
    [fromFromParams]
  );
  const to = useMemo(() => new Date(toFromParams as string), [toFromParams]);
  const ids = useMemo(() => idsFromParams?.split(","), [idsFromParams]);

  const dispatch = useDispatch();

  const {
    forecasts,
    loading,
    campaignsData: { campaigns },
  } = useSelector((state: StoreModel) => proposalRootSelector(state, false));

  const campaignsFiltered = useMemo(
    () =>
      campaigns
        .filter(({ id }) => ids?.includes(id.toString()))
        .sort(
          (a, b) =>
            Number(new Date(a.startDate)) - Number(new Date(b.startDate))
        ),
    [campaigns, ids]
  );

  const updateCampaigns = useCallback(() => {
    dispatch(receiveCampaignsAction.request({ from, to }));
  }, [dispatch, from, to]);

  const updateForecasts = useCallback(() => {
    dispatch(
      receiveForecastsForProposalAction.request({
        forecastInputInitialRequests: {
          from,
          to,
          medium,
          productId: campaignsFiltered?.[0].productId,
          initialRequestIds: campaignsFiltered.map((s) => s.id),
        },
      })
    );
  }, [campaignsFiltered, dispatch, from, medium, to]);

  useEffect(() => {
    if (campaigns.length === 0) {
      updateCampaigns();
    }
  }, [campaigns.length, updateCampaigns]);

  useEffect(() => {
    if (campaignsFiltered.length !== 0) {
      updateForecasts();
    }
  }, [
    campaigns.length,
    campaignsFiltered.length,
    updateCampaigns,
    updateForecasts,
  ]);

  return (
    <>
      <Helmet>
        <title>{i18n._(t`Voorstel`)}</title>
      </Helmet>

      <Pageheader
        title={<Trans>Voorstel</Trans>}
        icon={
          <Icons.PdfIcon
            width="100%"
            height="100%"
            fill="rgba(129, 176, 210, 0.2)"
          />
        }
      >
        <Button
          mode="secondary"
          onClick={updateCampaigns}
          disabled={loading}
          loading={loading}
        >
          <Trans>Ververs campagnes & prognoses</Trans>
        </Button>

        <ProposalRequest selectedCampaigns={campaignsFiltered} />
      </Pageheader>

      <ContentContainer className="content">
        <Notification />
        <Row gutter={[30, 30]}>
          <Col className="back-link">
            <CampaignBackButton
              append={new URLSearchParams({ selected: idsFromParams ?? "" })}
            />
          </Col>
        </Row>
        <Spinner spinning={loading}>
          <Row>
            <Col xs={24} md={24}>
              {forecasts &&
                campaignsFiltered.map((campaign) => (
                  <Row gutter={[40, 40]} key={campaign.id}>
                    <Col span={24}>
                      <ProposalCampaign
                        campaign={campaign}
                        forecast={forecasts[campaign.id]}
                      />
                    </Col>
                  </Row>
                ))}
              {forecasts && (
                <Row gutter={[40, 40]} key="all">
                  <Col span={24}>
                    <Typography.Title>
                      <Trans>Totaal prognose</Trans>
                    </Typography.Title>
                    <Forecast
                      medium={medium}
                      forecast={forecasts.all}
                      loading={false}
                    />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Spinner>
      </ContentContainer>
    </>
  );
});

export default ProposalWithForecast;
