import { AnyAction } from "redux";
import { ActionType, Reducer, createReducer } from "typesafe-actions";

import { PortalUser } from "../../api/models";
import { ReduxStoreState } from "../base";
import { LoadingPortalUser, TenantContext } from "../models";
import { receiveMeAction, setCurrentTenantAction } from "./actions";

export const accountReducer: Reducer<LoadingPortalUser, AnyAction> =
  createReducer({
    state: ReduxStoreState.Initial,
  })
    .handleAction(receiveMeAction.request, (state: PortalUser) => ({
      ...state,
      loading: true,
      state: ReduxStoreState.Loading,
    }))
    .handleAction(
      receiveMeAction.failure,
      (
        state: PortalUser,
        { payload: { status } }: ReturnType<typeof receiveMeAction.failure>
      ) => {
        if (status === 401) {
          return {
            ...state,
            loading: false,
            state: ReduxStoreState.Unauthorized,
          };
        }

        if (status === 403) {
          return {
            ...state,
            loading: false,
            state: ReduxStoreState.Forbidden,
          };
        }

        return {
          ...state,
          loading: false,
          state: ReduxStoreState.Failure,
        };
      }
    )
    .handleAction(
      receiveMeAction.success,
      (
        state: PortalUser,
        action: ReturnType<typeof receiveMeAction.success>
      ) => ({
        ...action.payload,
        loading: false,
        state: ReduxStoreState.Success,
      })
    );

export const tenantContextReducer = createReducer<
  TenantContext,
  ActionType<typeof setCurrentTenantAction>
>({
  currentTenant: undefined,
}).handleAction(setCurrentTenantAction, (state, action) => ({
  ...state,
  currentTenant: action.payload,
}));
