import { PortalUser } from "../api";

export const canProposeMultipleCampaigns = (account: PortalUser) =>
  isIntermediair(account) || isInternalUser(account);

export const canUseProspect = (account: PortalUser) =>
  isIntermediair(account) || isInternalUser(account);

export const isIntermediair = (account: PortalUser) =>
  account.roles.includes("Intermediair");

export const isInternalUser = (account: PortalUser) =>
  account.sterInlog.internalUser;

export const isInternalUserOrAdmin = (account: PortalUser) =>
  account.sterInlog.internalUser || account.roles.includes("Admin");
