import moment from "moment";
import { createSelector } from "reselect";

import {
  LoadingTermsAndConditionsResult,
  StoreModel,
} from "../../store/models";

export const termsDateSelector = ({
  settings,
}: StoreModel): Date | undefined =>
  !settings.loading && settings.settings?.terms_date !== undefined
    ? moment(settings.settings.terms_date).toDate()
    : undefined;

export const termsSelector = ({
  terms,
}: StoreModel): LoadingTermsAndConditionsResult => terms;

export const showTermsSelector = createSelector(
  [
    ({ settings }: StoreModel): boolean =>
      Boolean(settings.settings?.enable_terms),
    termsDateSelector,
    termsSelector,
  ],
  (enableTerms, termsDate, terms): boolean => {
    if (termsDate === undefined || terms.loading) {
      return false;
    }

    return (
      enableTerms &&
      (terms.dateOfTerms === undefined ||
        !moment(terms.dateOfTerms).isSame(moment(termsDate)))
    );
  }
);
