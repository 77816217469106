import { i18n } from "@lingui/core";
import { Trans, t } from "@lingui/macro";
import {
  Button,
  Container,
  ContentContainer,
  Form,
  Option,
  Row,
  Select,
  Spinner,
  Typography,
} from "@ster/ster-toolkit";
import { Col } from "antd";
import { SelectValue } from "antd/lib/select";
import Cookies from "js-cookie";
import { memo, useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";

import { receiveAdvertisersAction } from "../../store/advertisers/actions";
import { receiveOrganisationsAction } from "../../store/organisations/actions";
import {
  defaultCookieOptions,
  getOrganisationCode,
  useQuery,
} from "../../utils";
import {
  advertiserCookieName,
  klantportalAdverteerder,
  organisationCookieName,
} from "../../utils/constants";
import styles from "./Organisation.module.less";
import rootSelectors from "./selectors";

const OrganisationContainer = memo(() => {
  const dispatch = useDispatch();
  const params = useQuery();

  const {
    account,
    organisations,
    organisationsLoading,
    advertisers,
    advertisersLoading,
  } = useSelector(rootSelectors);

  const existingAdvertiserCookie = JSON.parse(
    Cookies.get(advertiserCookieName) ?? "null"
  );

  const [selectedAdvertiser, setSelectedAdvertiser] = useState(
    existingAdvertiserCookie
  );
  const [selectedOrganisation, setSelectedOrganisation] = useState(
    getOrganisationCode(account)
  );

  const [showAdvertisers, setShowAdvertisers] = useState(
    !!existingAdvertiserCookie
  );

  useEffect((): void => {
    if (!account || !account.roles || !account.roles.includes("Medewerker")) {
      return;
    }

    if (organisations.length === 0 && !organisationsLoading) {
      dispatch(receiveOrganisationsAction.request());
    }

    if (advertisers.length === 0 && !advertisersLoading) {
      dispatch(receiveAdvertisersAction.request());
    }
  }, [
    account,
    advertisers.length,
    advertisersLoading,
    dispatch,
    organisations.length,
    organisationsLoading,
  ]);

  const onChangeOrganisation = useCallback((value: SelectValue) => {
    if (value === klantportalAdverteerder) {
      setShowAdvertisers(true);
    } else {
      setShowAdvertisers(false);
      setSelectedAdvertiser("");
    }
    setSelectedOrganisation(value as string);
  }, []);

  const onChangeAdvertiser = useCallback((value: SelectValue) => {
    setSelectedAdvertiser(value as string);
  }, []);

  const onFinish = useCallback(() => {
    if (!selectedOrganisation) {
      return;
    }

    if (selectedOrganisation === klantportalAdverteerder) {
      if (existingAdvertiserCookie !== selectedAdvertiser) {
        Cookies.remove(advertiserCookieName);
        Cookies.remove(organisationCookieName);
        Cookies.set(
          advertiserCookieName,
          selectedAdvertiser as string,
          defaultCookieOptions
        );
        Cookies.set(
          organisationCookieName,
          selectedOrganisation as string,
          defaultCookieOptions
        );
      }
    } else if (getOrganisationCode(account) !== selectedOrganisation) {
      if (existingAdvertiserCookie) {
        Cookies.remove(advertiserCookieName);
      }
      Cookies.remove(organisationCookieName);
      Cookies.set(
        organisationCookieName,
        selectedOrganisation as string,
        defaultCookieOptions
      );
    }

    window.location.href = params.get("next") ?? "/";
  }, [
    account,
    existingAdvertiserCookie,
    params,
    selectedAdvertiser,
    selectedOrganisation,
  ]);

  return (
    <>
      <Helmet>
        <title>{i18n._(t`Inloggen als`)}</title>
      </Helmet>

      <ContentContainer>
        <Spinner spinning={organisationsLoading || advertisersLoading}>
          <Row gutter={[24, 0]} style={{ marginTop: 40 }}>
            <Col span={18} offset={3}>
              <Container>
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <Typography.Title className={styles.typographyCenter}>
                      <Trans>Inloggen als</Trans>
                    </Typography.Title>
                  </Col>

                  <Col span={20} offset={2}>
                    <Typography.Paragraph className={styles.typographyCenter}>
                      <Trans>
                        Met behulp van onderstaande zoek-veld(en) kun je een
                        organisatie kiezen. Wanneer je wilt inloggen als
                        adverteerder, kies je eerst voor de organisatie
                        &quot;Klantportal adverteerder&quot; en vervolgens kies
                        je de gewenste adverteerder door ernaar te zoeken.
                      </Trans>
                    </Typography.Paragraph>
                  </Col>
                </Row>

                {organisations.length > 0 && advertisers.length > 0 && (
                  <Form
                    onFinish={onFinish}
                    initialValues={{
                      orgcode: getOrganisationCode(account),
                      advcode: existingAdvertiserCookie,
                    }}
                  >
                    <Row gutter={[24, 0]} justify="center">
                      <Col span={12} lg={8}>
                        <Form.Item
                          name="orgcode"
                          label={i18n._(t`Organisatie`)}
                          rules={[{ required: true }]}
                        >
                          <Select.Search
                            placeholder={i18n._(t`Zoek`)}
                            onChange={onChangeOrganisation}
                            optionFilterProp="search"
                            autoFocus
                            showAction={["focus"]}
                          >
                            {organisations.map(({ code, name }) => (
                              <Option
                                value={code ?? ""}
                                key={code ?? ""}
                                search={`${name} ${code}`}
                              >
                                {name} ({code})
                              </Option>
                            ))}
                          </Select.Search>
                        </Form.Item>
                      </Col>
                    </Row>
                    {showAdvertisers && (
                      <Row gutter={[24, 0]} justify="center">
                        <Col span={12} lg={8}>
                          <Form.Item
                            name="advcode"
                            label={i18n._(t`Adverteerder`)}
                            rules={[{ required: true }]}
                          >
                            <Select.Search
                              placeholder={i18n._(t`Zoek`)}
                              onChange={onChangeAdvertiser}
                              optionFilterProp="search"
                            >
                              {advertisers.map(({ id, name }) => (
                                <Option
                                  value={id}
                                  key={id}
                                  search={`${name} ${id}`}
                                >
                                  {name}
                                </Option>
                              ))}
                            </Select.Search>
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                    <Row gutter={[24, 0]}>
                      <Col span={14} lg={10} offset={6}>
                        <Form.Item name="submit">
                          <Row gutter={[8, 0]} justify="end">
                            <Button
                              mode="primary"
                              type="primary"
                              htmlType="submit"
                            >
                              {showAdvertisers ? (
                                <Trans>Kies adverteerder</Trans>
                              ) : (
                                <Trans>Kies organisatie</Trans>
                              )}
                            </Button>
                          </Row>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Container>
            </Col>
          </Row>
        </Spinner>
      </ContentContainer>
    </>
  );
});

export default OrganisationContainer;
