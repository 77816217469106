import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

import { ForecastApi } from "../../api/apis";
import apiConfig from "../../apiConfig";
import { toError } from "../utils";
import { receiveForecastsForProposalAction } from "./actions";

// eslint-disable-next-line import/prefer-default-export
export function* receiveForecastsForProposal(
  action: ReturnType<typeof receiveForecastsForProposalAction.request>
): SagaIterator {
  try {
    const forecastApi = new ForecastApi(apiConfig());
    const response = yield call(() =>
      forecastApi.apiV1ForecastInitialRequestsPost(action.payload)
    );
    yield put(receiveForecastsForProposalAction.success(response));
  } catch (err) {
    yield put(receiveForecastsForProposalAction.failure(toError(err)));
  }
}
