import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Divider, Form, Input, Row } from "@ster/ster-toolkit";
import { Col, FormInstance } from "antd";
import { Store } from "antd/lib/form/interface";
import { memo, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import {
  Advertiser,
  OrderRequest,
  SalesPeriod,
  SettingsResult,
} from "../../../api";
import { StoreModel } from "../../../store/models";
import { isIntermediair, isInternalUser } from "../../../utils/userHelper";
import { validateForbiddenWords } from "../../../utils/validators";
import styles from "./Details.module.less";
import DetailsCrm from "./DetailsCrm";
import DetailsEditPeriodAdvertiser from "./DetailsEditPeriodAdvertiser";
import DetailsReadPeriodAdvertiser from "./DetailsReadPeriodAdvertiser";

// eslint-disable-next-line no-shadow
export enum AdvertiserTypeEnum {
  Existing = "existing",
  Prospect = "prospect",
}

interface DetailsEditProps {
  selectedSalesPeriods: SalesPeriod[] | undefined;
  order: OrderRequest | undefined;
  productsByAdvertiser: Advertiser[];
  form: FormInstance<unknown>;
  advertiserType: AdvertiserTypeEnum;
  onUpdate: (values: Store) => void;
  settings: SettingsResult;
}

const DetailsEdit = memo(
  ({
    selectedSalesPeriods,
    order,
    productsByAdvertiser,
    form,
    advertiserType,
    onUpdate,
    settings,
  }: DetailsEditProps) => {
    const { i18n } = useLingui();
    const account = useSelector((store: StoreModel) => store.account);

    /**
     * This arganisation has only one advertiser;
     * select this advertiser
     */
    useEffect(() => {
      if (productsByAdvertiser?.length === 1 && !order?.advertiserId) {
        const firstAdvertiser = productsByAdvertiser[0];
        const newValue = { advertiserId: firstAdvertiser.id };
        form.setFieldsValue(newValue);
        onUpdate(newValue);
      }
    }, [form, onUpdate, order?.advertiserId, productsByAdvertiser]);

    /**
     * The selected advertiser has only one product (= Sternummer);
     * select this product
     */
    useEffect(() => {
      if (order?.advertiserId && productsByAdvertiser) {
        const selectedAdvertiser = productsByAdvertiser.find(
          ({ id }) => id === order.advertiserId
        );
        if (selectedAdvertiser?.products?.length === 1 && !order?.productId) {
          const firstProduct = selectedAdvertiser.products[0];
          const newValue = { productId: firstProduct.id };
          form.setFieldsValue(newValue);
          onUpdate(newValue);
        }
      }
    }, [
      form,
      onUpdate,
      order?.advertiserId,
      order?.productId,
      productsByAdvertiser,
    ]);

    const selectedProspectAdvertiser = useMemo(
      () =>
        productsByAdvertiser?.find((p) => p.id === order?.advertiserId)
          ?.isProspect,
      [order?.advertiserId, productsByAdvertiser]
    );

    const canSetCustomAdvertiser = useMemo(
      () => selectedProspectAdvertiser && isIntermediair(account),
      [account, selectedProspectAdvertiser]
    );

    const canSetCustomContact = useMemo(
      () => isIntermediair(account),
      [account]
    );

    const isExistingConcept = useMemo(
      () => order?.id || (order?.subOrders?.length ?? 0) > 0,
      [order?.id, order?.subOrders?.length]
    );

    return (
      <>
        {isExistingConcept ? (
          <DetailsReadPeriodAdvertiser
            productsByAdvertiser={productsByAdvertiser}
            order={order}
            showDigitalReportWarning={Boolean(
              settings?.settings?.show_digital_report_warning
            )}
          />
        ) : (
          <DetailsEditPeriodAdvertiser
            productsByAdvertiser={productsByAdvertiser}
            order={order}
            account={account}
            advertiserType={advertiserType}
            selectedSalesPeriods={selectedSalesPeriods}
            showDigitalReportWarning={Boolean(
              settings?.settings?.show_digital_report_warning
            )}
          />
        )}
        {canSetCustomAdvertiser && (
          <Form.Item
            name="advertiser"
            label={i18n._(t`Adverteerder`)}
            rules={[
              {
                required: true,
                message: i18n._(t`Vul een adverteer in`),
              },
            ]}
          >
            <Input
              placeholder={i18n._(t`Naam voor je adverteerder...`)}
              className={styles.formInputWidth}
            />
          </Form.Item>
        )}
        <DetailsCrm
          advertiserId={order?.advertiserId}
          crmAccount={order?.crmAccount}
          crmOpportunity={order?.crmOpportunity}
          editable
        />
        {canSetCustomContact && (
          <Form.Item
            name="contact"
            label={i18n._(t`Contactpersoon`)}
            rules={[
              {
                required: selectedProspectAdvertiser && isIntermediair(account),
                message: i18n._(t`Vul een contactpersoon in`),
              },
            ]}
          >
            <Input
              placeholder={i18n._(t`Naam voor je contactpersoon...`)}
              className={styles.formInputWidth}
            />
          </Form.Item>
        )}
        <Form.OptionalItem
          name="campaignName"
          label={i18n._(t`Campagnenaam`)}
          optionalLabel={i18n._(t`(optioneel)`)}
          rules={[{ validator: validateForbiddenWords(i18n) }]}
        >
          <Input
            placeholder={i18n._(t`Naam voor je campagne...`)}
            className={styles.formInputWidth}
            maxLength={30}
          />
        </Form.OptionalItem>
        <Form.OptionalItem
          name="comments"
          label={i18n._(t`Opmerkingen`)}
          optionalLabel={i18n._(t`(optioneel)`)}
          rules={[{ validator: validateForbiddenWords(i18n) }]}
        >
          <Input.TextArea
            placeholder={i18n._(t`Eventuele opmerkingen...`)}
            className={styles.formInputWidth}
            maxLength={1500}
          />
        </Form.OptionalItem>
        <Form.OptionalItem
          name="invoice"
          label={i18n._(t`Factuurnummer`)}
          optionalLabel={i18n._(t`(optioneel)`)}
          rules={[{ validator: validateForbiddenWords(i18n) }]}
        >
          <Input
            placeholder={i18n._(t`Factuurnummer...`)}
            className={styles.formInputWidth}
            maxLength={30}
          />
        </Form.OptionalItem>
        {isInternalUser(account) && (
          <>
            <Divider>{i18n._(t`Contactpersoon`)}</Divider>
            <Form.OptionalItem name="contact" label={i18n._(t`Naam`)}>
              <Input
                placeholder={i18n._(t`Naam van het contactpersoon...`)}
                className={styles.formInputWidth}
              />
            </Form.OptionalItem>
            <Form.OptionalItem
              name="contactEmailExtra"
              label={i18n._(t`E-mailadres`)}
            >
              <Input
                placeholder={i18n._(t`E-mailadres van het contactpersoon...`)}
                className={styles.formInputWidth}
              />
            </Form.OptionalItem>
          </>
        )}
        <Row justify="end">
          <Col>
            <Form.Item shouldUpdate>
              {(): React.ReactElement => (
                <Button
                  mode="secondary"
                  htmlType="submit"
                  disabled={!form.isFieldsTouched(false) && !order}
                >
                  <Trans>Volgende</Trans>
                </Button>
              )}
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
);

export default DetailsEdit;
