import { Trans } from "@lingui/macro";
import { Icons } from "@ster/ster-toolkit";
import { Popover } from "antd";
import { memo, useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import {
  MediumEnum,
  OrderListItem,
  OrderStatus,
  SettingsResult,
} from "../../../../api";
import { clearCopyCampaignAction } from "../../../../store/campaigns/actions";
import { getFormattedDate } from "../../../../utils/dateHelper";
import { useAvailableMediumTypes } from "../../../../utils/hooks";
import {
  useCampaignAnalysisDisabled,
  useCampaignAnalysisVisible,
} from "../CampaignDetail/CampaignAnalysisHooks";
import CampaignAnalyisText from "../CampaignDetail/CampaignAnalysisText";
import CampaignCopyButton from "./CampaignCopyButton";
import CampaignCopyModal from "./CampaignCopyModal";

const CampaignContextMenu = memo(
  ({
    order,
    showDelete,
    onDelete,
    settings,
    canEdit,
  }: {
    order: OrderListItem;
    showDelete: boolean;
    onDelete?: (orderListItem: OrderListItem) => void;
    settings?: SettingsResult;
    canEdit?: boolean;
  }) => {
    const dispatch = useDispatch();
    const [showPopup, setShowPopup] = useState(false);
    const [showCopyModal, setShowCopyModal] = useState(false);
    const { status: campaignStatus, medium, id: orderId } = order;
    const { DeleteIcon, MoreIcon, SchemaIcon, EyeIcon, EditIcon } = Icons;

    const [availableMediumTypes] = useAvailableMediumTypes(
      "aanvragen_formulier"
    );

    const showSchedule = useMemo(
      () =>
        campaignStatus !== OrderStatus.Concept &&
        campaignStatus !== OrderStatus.Submitted &&
        order.medium !== MediumEnum.Inter,
      [campaignStatus, order.medium]
    );

    const showCopy = useMemo(
      () =>
        /**
         * Alleen orders kopieren met GRP's/impressies of concepten
         * (bij maandoverschrijdende order kan alleen vanuit het eerste deel gekopieerd worden)
         */
        canEdit &&
        (!order.spreadedOrderId || order.spreadedFirstPart) &&
        (order.id < 0 ||
          (order.medium === MediumEnum.Inter
            ? order.hasRequestedImpressions
            : order.hasRequestedGrps)),
      [
        canEdit,
        order.hasRequestedGrps,
        order.hasRequestedImpressions,
        order.id,
        order.medium,
        order.spreadedFirstPart,
        order.spreadedOrderId,
      ]
    );

    const showEdit = useMemo(
      () =>
        order.isEditable &&
        order.spreadedOrderId == null &&
        availableMediumTypes.includes(order.medium) &&
        settings?.settings?.enable_edit_campaign?.[order.medium],
      [
        availableMediumTypes,
        order.isEditable,
        order.medium,
        order.spreadedOrderId,
        settings?.settings?.enable_edit_campaign,
      ]
    );

    const isCampaignAnalysisVisible = useCampaignAnalysisVisible(
      order,
      settings
    );
    const isCampaignAnalysisDisabled = useCampaignAnalysisDisabled(order);

    const onClickDelete = useCallback(() => {
      onDelete?.(order);
    }, [onDelete, order]);

    const handleTogglePopup = useCallback((visible: boolean) => {
      setShowPopup(visible);
    }, []);

    const handleOpenCopyModal = useCallback(() => {
      dispatch(clearCopyCampaignAction());
      handleTogglePopup(false);
      setShowCopyModal(true);
    }, [dispatch, handleTogglePopup]);

    const handleCloseCopyModal = useCallback(() => {
      setShowCopyModal(false);
    }, []);

    const ContextMenu = useCallback(
      () => (
        <div>
          {showSchedule && (
            <Link
              to={`/campaigns/schedule/${medium}/${orderId}`}
              className="context-menu__link"
            >
              <SchemaIcon />
              <Trans>Uitzendschema</Trans>
            </Link>
          )}
          {showEdit && (
            <Link
              to={
                order.status === OrderStatus.Concept
                  ? `/campaigns/new/${medium}/${orderId}/${getFormattedDate(
                      order.startDate
                    )}/${getFormattedDate(order.endDate)}`
                  : `/campaigns/edit/${medium}/${orderId}`
              }
              className="context-menu__link"
            >
              <EditIcon fill="#000" />
              <Trans>Wijzigen</Trans>
            </Link>
          )}
          {showCopy && <CampaignCopyButton onOpen={handleOpenCopyModal} />}
          {isCampaignAnalysisVisible &&
            (isCampaignAnalysisDisabled ? (
              <div className="context-menu__link disabled">
                <EyeIcon width={24} fill="#aeaeae" />
                <CampaignAnalyisText shortText analysis={order.analysis} />
              </div>
            ) : (
              <Link
                to={`/campaigns/${medium}/${orderId}?analysis`}
                className="context-menu__link"
              >
                <EyeIcon width={24} fill="#000" />
                <CampaignAnalyisText shortText analysis={order.analysis} />
              </Link>
            ))}
          {showDelete && (
            <button
              type="button"
              className="link context-menu__link"
              onClick={onClickDelete}
            >
              <DeleteIcon fill="#000" />
              <Trans>Verwijderen</Trans>
            </button>
          )}
        </div>
      ),
      [
        DeleteIcon,
        EditIcon,
        EyeIcon,
        SchemaIcon,
        handleOpenCopyModal,
        medium,
        onClickDelete,
        order.analysis,
        order.endDate,
        order.startDate,
        order.status,
        orderId,
        showCopy,
        showDelete,
        showEdit,
        showSchedule,
        isCampaignAnalysisDisabled,
        isCampaignAnalysisVisible,
      ]
    );

    if (
      !showDelete &&
      !showSchedule &&
      !order.analysis.isAvailable &&
      !showCopy
    ) {
      return null;
    }

    return (
      <>
        <Popover
          trigger="click"
          content={<ContextMenu />}
          placement="bottomRight"
          overlayClassName=""
          open={showPopup}
          onOpenChange={handleTogglePopup}
        >
          <button type="button" className="more-menu-btn">
            <MoreIcon height={28} />
          </button>
        </Popover>

        {showCopyModal && (
          <CampaignCopyModal order={order} onClose={handleCloseCopyModal} />
        )}
      </>
    );
  }
);

export default CampaignContextMenu;
