import {
  accountSelector,
  advertisersLoadingSelector,
  advertisersSelector,
  createStructuredAppSelector,
  organisationsLoadingSelector,
  organisationsSelector,
} from "../../shared/selectors";

export const rootSelectors = createStructuredAppSelector({
  account: accountSelector,
  organisations: organisationsSelector,
  organisationsLoading: organisationsLoadingSelector,
  advertisers: advertisersSelector,
  advertisersLoading: advertisersLoadingSelector,
});

export default rootSelectors;
