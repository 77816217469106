import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Tooltip } from "@ster/ster-toolkit";
import { memo } from "react";

export interface SubOrderFooterOnlineProps {
  orderId: number;
  subOrderId: number;
  isReady: boolean;
}

const SubOrderFooterOnline = memo(
  ({ orderId, subOrderId, isReady }: SubOrderFooterOnlineProps) => {
    const { i18n } = useLingui();

    return (
      <>
        <Button
          rel="noopener noreferrer"
          target="_blank"
          mode="tertiary"
          href={`/delivery/${orderId}/${subOrderId}.xls`}
        >
          <Tooltip title={i18n._(t`Download een Excel-sheet van 'deliveries'`)}>
            <span>
              <Trans>Delivery</Trans>
            </span>
          </Tooltip>
        </Button>
        <Button
          rel="noopener noreferrer"
          target="_blank"
          mode="tertiary"
          href={`/api/v1/campaign/endreport/inter/${orderId}/${subOrderId}`}
        >
          <Tooltip
            title={
              isReady
                ? i18n._(
                    t`Download een Excel-sheet met de eindrapportage van deze deelorder`
                  )
                : i18n._(
                    t`Download een Excel-sheet met de tussenrapportage van deze deelorder`
                  )
            }
          >
            <span>
              {isReady
                ? i18n._(t`Eindrapportage Excel`)
                : i18n._(t`Tussenrapportage Excel`)}
            </span>
          </Tooltip>
        </Button>
        <Button
          rel="noopener noreferrer"
          target="_blank"
          mode="tertiary"
          href={`/api/v1/campaign/endreport/inter/${orderId}/${subOrderId}/pptx`}
        >
          <Tooltip
            title={
              isReady
                ? i18n._(
                    t`Download een PowerPoint met de eindrapportage van deze deelorder`
                  )
                : i18n._(
                    t`Download een PowerPoint met de tussenrapportage van deze deelorder`
                  )
            }
          >
            <span>
              {isReady
                ? i18n._(t`Eindrapportage PowerPoint`)
                : i18n._(t`Tussenrapportage PowerPoint`)}
            </span>
          </Tooltip>
        </Button>
      </>
    );
  }
);

export default SubOrderFooterOnline;
