import { useMemo } from "react";

import { AnalysisAvailability, MediumEnum } from "../../../../api";
import { SettingsResult } from "../../../../api/models/SettingsResult";

export const useCampaignAnalysisVisible = (
  campaignData: {
    analysis: AnalysisAvailability;
    medium: MediumEnum;
  },
  settings: SettingsResult | undefined
) =>
  useMemo<boolean>(
    () => isCampaignAnalysisVisible(campaignData, settings),
    [campaignData, settings]
  );

export const useCampaignAnalysisDisabled = (campaignData: {
  analysis: AnalysisAvailability;
}) =>
  useMemo<boolean>(
    () => isCampaignAnalysisDisabled(campaignData),
    [campaignData]
  );

export const isCampaignAnalysisVisible = (
  campaignData: {
    analysis: AnalysisAvailability;
    medium: MediumEnum;
  },
  settings: SettingsResult | undefined
): boolean =>
  !!campaignData.analysis.from &&
  Boolean(
    settings?.settings?.enable_campaign_analysis[campaignData.medium] ?? true
  ) &&
  !!settings?.settings?.nmoSwitchDate;

export const isCampaignAnalysisDisabled = (campaignData: {
  analysis: AnalysisAvailability;
}): boolean => campaignData.analysis.isAvailable === false;
