import { SagaIterator } from "redux-saga";
import { call, put, select } from "redux-saga/effects";
import { ActionType } from "typesafe-actions";

import { TenantEnum } from "../../api";
import { AccountApi } from "../../api/apis";
import apiConfig from "../../apiConfig";
import { availableTenantsSelector } from "../../shared/selectors";
import {
  getTenantFromCookie,
  isAccessTokenValid,
  logout,
  setTenantInCookie,
} from "../../utils";
import { receiveSettingsAction } from "../settings/actions";
import { toError } from "../utils";
import {
  logoutMeAction,
  receiveMeAction,
  setCurrentTenantAction,
} from "./actions";

export function* receiveMe(): SagaIterator {
  try {
    const accountApi = new AccountApi(apiConfig());
    if (isAccessTokenValid()) {
      const response = yield call(() => accountApi.apiV1AccountMeGet());
      yield put(receiveMeAction.success(response));
    } else {
      yield put(receiveMeAction.failure(toError({ status: 401 } as Response)));
    }
  } catch (err) {
    yield put(receiveMeAction.failure(toError(err)));
  }
}

export function* logoutMe(): SagaIterator {
  try {
    logout();
    yield put(receiveSettingsAction.request());
    yield put(logoutMeAction.success());
  } catch (err) {
    yield put(logoutMeAction.failure(toError(err)));
  }
}

// Side effect when current tenant is set into the store
// - store in api config (to send as header)
// - store in cookie (only if it is different)
// - refresh window (unless there was no value set yet, in which case it is the initial load)
export function* setCurrentTenantSaga(
  action: ActionType<typeof setCurrentTenantAction>
): SagaIterator {
  // Conditionally store it in the cookie if it is of a different value
  const currentTenantInCookie = getTenantFromCookie();
  if (currentTenantInCookie !== action.payload) {
    // value is different, so let's store it.
    yield call(setTenantInCookie, action.payload);

    // when cookie needs to change we can also assume it is a fresh tenant selection and need to refresh the window - but then only if there was a different tenant previously
    // ** refreshing window because --magic-- reactivity is not properly implemented
    if (currentTenantInCookie !== undefined) {
      window.location.reload();
    }
  }
}

const preferredTenant = TenantEnum.National;

// Saga to conditionally check and set current tenant
export function* checkAndSetCurrentTenantSaga(): SagaIterator {
  // Data to work with
  const tenantFromCookie = getTenantFromCookie();
  const availableTenants: TenantEnum[] | null | undefined = yield select(
    availableTenantsSelector
  );

  if (!availableTenants) {
    return; // when no data then just exit
  }

  if (availableTenants.length === 0) {
    // no tenant available for this user
    yield put(setCurrentTenantAction(undefined));
    return;
  }

  if (tenantFromCookie && availableTenants.includes(tenantFromCookie)) {
    yield put(setCurrentTenantAction(tenantFromCookie));
    return;
  }

  const tenantToSet = availableTenants.includes(preferredTenant)
    ? preferredTenant
    : availableTenants[0];
  yield put(setCurrentTenantAction(tenantToSet));
}
