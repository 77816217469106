/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface PackagePropertiesTv
 */
export interface PackagePropertiesTv {
  /**
   *
   * @type {string}
   * @memberof PackagePropertiesTv
   */
  code: string;
  /**
   *
   * @type {boolean}
   * @memberof PackagePropertiesTv
   */
  hasSpread: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PackagePropertiesTv
   */
  hasPremium: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PackagePropertiesTv
   */
  hasPreferencePosition: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PackagePropertiesTv
   */
  hasHotspot: boolean;
}

export function PackagePropertiesTvFromJSON(json: any): PackagePropertiesTv {
  return PackagePropertiesTvFromJSONTyped(json, false);
}

export function PackagePropertiesTvFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PackagePropertiesTv {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    code: json["code"],
    hasSpread: json["hasSpread"],
    hasPremium: json["hasPremium"],
    hasPreferencePosition: json["hasPreferencePosition"],
    hasHotspot: json["hasHotspot"],
  };
}

export function PackagePropertiesTvToJSON(
  value?: PackagePropertiesTv | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    code: value.code,
    hasSpread: value.hasSpread,
    hasPremium: value.hasPremium,
    hasPreferencePosition: value.hasPreferencePosition,
    hasHotspot: value.hasHotspot,
  };
}
