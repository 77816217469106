/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ClaimValue,
  ClaimValueFromJSON,
  ClaimValueFromJSONTyped,
  ClaimValueToJSON,
} from "./";

/**
 *
 * @export
 * @interface SettingsResult
 */
export interface SettingsResult {
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof SettingsResult
   */
  settings?: { [key: string]: any } | null;
  /**
   *
   * @type {Array<ClaimValue>}
   * @memberof SettingsResult
   */
  claims?: Array<ClaimValue> | null;
}

export function SettingsResultFromJSON(json: any): SettingsResult {
  return SettingsResultFromJSONTyped(json, false);
}

export function SettingsResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SettingsResult {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    settings: !exists(json, "settings") ? undefined : json["settings"],
    claims: !exists(json, "claims")
      ? undefined
      : json["claims"] === null
        ? null
        : (json["claims"] as Array<any>).map(ClaimValueFromJSON),
  };
}

export function SettingsResultToJSON(value?: SettingsResult | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    settings: value.settings,
    claims:
      value.claims === undefined
        ? undefined
        : value.claims === null
          ? null
          : (value.claims as Array<any>).map(ClaimValueToJSON),
  };
}
