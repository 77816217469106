import { combineReducers } from "redux";

import { accountReducer, tenantContextReducer } from "./account/reducers";
import {
  getEditableSettingsReducer,
  updateEditableSettingReducer,
} from "./admin/reducers";
import { advertisersReducer } from "./advertisers/reducers";
import {
  exportSpotTrackerReducer,
  receiveSpotTrackerReducer,
} from "./analysis/reducers";
import {
  announcementsReducer,
  publishedAnnouncementsReducer,
} from "./announcements/reducers";
import { bookSpotReducer, breaksReducer } from "./breakselect/reducers";
import { getAnalysisNmoNotificationReducer } from "./campaignAnalysis/reducers";
import {
  analysisUploadFileReducer,
  bookingDateReducer,
  campaignConfigurationReducer,
  contextTargetsReducer,
  forecastConversionGroupsReducer,
  groupedPackagesRadioReducer,
  groupedPackagesTvReducer,
  importConfigurationReducer,
  initialRequestReducer,
  packagesReducer,
  productsReducer,
  programsReducer,
  proposalGenerateReducer,
  proposalUploadForecastReducer,
  receiveCalculationReducer,
  receiveCustomerAccountsReducer,
  receiveCustomerContactsReducer,
  receiveCustomerOpportunitiesReducer,
  receiveForecastReducer,
  salesPeriodsReducer,
  sitesReducer,
  spotIndexesReducer,
  targetGroupsReducer,
} from "./campaignCreate/reducers";
import {
  campaignAnalysisGenericReducer,
  campaignAnalysisReducer,
  campaignAnalysisTargetGroupsReducer,
  campaignDetailReducer,
  campaignDetailWithSpotsReducer,
  campaignExportReducer,
  campaignInstructionsReducer,
  campaignSpotsDownloadReducer,
  campaignSpotsReducer,
  deleteInstructionReducer,
  requestRelatedCampaignsReducer,
  saveCampaignInstructionsOnlineReducer,
  saveCampaignInstructionsReducer,
  saveMultiCampaignInstructionsReducer,
  validateCampaignReducer,
} from "./campaignDetail/reducers";
import {
  receiveCampaignInstructionsOnlineReducer,
  receiveEditableCampaignReducer,
  updateCampaignReducer,
} from "./campaignEdit/reducers";
import {
  acceptAvailableCampaignReducer,
  forwardCampaignReducer,
  receiveAvailableCampaignDetailReducer,
  receiveAvailableCampaignsReducer,
  receiveContactsReducer,
  receiveProductsReducer,
  rejectAvailableCampaignReducer,
} from "./campaignForward/reducers";
import {
  campaignsReducer,
  copyCampaignReducer,
  deleteCampaignReducer,
} from "./campaigns/reducers";
import {
  commercialRegistrationStatusReducer,
  commercialsForAuditReducer,
  commercialsForProductIdReducer,
  commercialsReducer,
  npoPlayerUrlReducer,
  reactivateCommercialReducer,
  uploadCommercialReducer,
} from "./commercials/reducers";
import { currentSpotsReducer, tipsReducer } from "./dashboard/reducers";
import { documentsReducer } from "./documents/reducers";
import { secondaryTargetGroupsReducer } from "./generic/reducers";
import { invoicesReducer } from "./invoices/reducers";
import { organisationsReducer } from "./organisations/reducers";
import { productTourReducer } from "./productTour/reducers";
import { forecastsForProposalReducer } from "./proposal/reducers";
import { settingsReducer } from "./settings/reducers";
import { termsReducer } from "./terms/reducers";
import {
  userReducer,
  usersExportReducer,
  usersReducer,
} from "./users/reducers";

export default combineReducers({
  account: accountReducer,
  tenantContext: tenantContextReducer,
  settings: settingsReducer,
  campaigns: campaignsReducer,
  copyCampaign: copyCampaignReducer,
  campaignConfiguration: campaignConfigurationReducer,
  importConfiguration: importConfigurationReducer,
  campaignDetail: campaignDetailReducer,
  campaignDetailWithSpots: campaignDetailWithSpotsReducer,
  campaignSpots: campaignSpotsReducer,
  campaignInstructions: campaignInstructionsReducer,
  campaignSpotsDownload: campaignSpotsDownloadReducer,
  campaignExport: campaignExportReducer,
  invoices: invoicesReducer,
  commercials: commercialsReducer,
  commercialsForProductId: commercialsForProductIdReducer,
  commercialsForAudit: commercialsForAuditReducer,
  commercialReview: commercialRegistrationStatusReducer,
  reactivateCommercial: reactivateCommercialReducer,
  npoPlayerUrl: npoPlayerUrlReducer,
  documents: documentsReducer,
  organisations: organisationsReducer,
  breaks: breaksReducer,
  salesPeriods: salesPeriodsReducer,
  products: productsReducer,
  initialRequest: initialRequestReducer,
  advertisers: advertisersReducer,
  spotIndexes: spotIndexesReducer,
  packages: packagesReducer,
  bookingDate: bookingDateReducer,
  groupedPackagesRadio: groupedPackagesRadioReducer,
  groupedPackagesTv: groupedPackagesTvReducer,
  currentSpots: currentSpotsReducer,
  secondaryTargetGroups: secondaryTargetGroupsReducer,
  terms: termsReducer,
  users: usersReducer,
  usersExport: usersExportReducer,
  user: userReducer,
  bookSpot: bookSpotReducer,
  deleteCampaign: deleteCampaignReducer,
  forecast: receiveForecastReducer,
  forecastConversionGroups: forecastConversionGroupsReducer,
  sites: sitesReducer,
  contextTargets: contextTargetsReducer,
  programs: programsReducer,
  targetGroups: targetGroupsReducer,
  announcements: announcementsReducer,
  publishedAnnouncements: publishedAnnouncementsReducer,
  proposalGenerate: proposalGenerateReducer,
  saveCampaignInstructions: saveCampaignInstructionsReducer,
  saveMultiCampaignInstructions: saveMultiCampaignInstructionsReducer,
  deleteInstruction: deleteInstructionReducer,
  proposalUploadForecast: proposalUploadForecastReducer,
  forecastsForProposal: forecastsForProposalReducer,
  productTour: productTourReducer,
  validateCampaign: validateCampaignReducer,
  campaignAnalysis: campaignAnalysisReducer,
  campaignAnalysisGeneric: campaignAnalysisGenericReducer,
  campaignAnalysisTargetGroups: campaignAnalysisTargetGroupsReducer,
  uploadCommercial: uploadCommercialReducer,
  campaignForward: combineReducers({
    forwarded: forwardCampaignReducer,
    contacts: receiveContactsReducer,
    products: receiveProductsReducer,
    availableCampaigns: receiveAvailableCampaignsReducer,
    acceptAvailableCampaign: acceptAvailableCampaignReducer,
    rejectAvailableCampaign: rejectAvailableCampaignReducer,
    availableCampaignDetail: receiveAvailableCampaignDetailReducer,
  }),
  updateCampaign: updateCampaignReducer,
  campaignInstructionsOnline: receiveCampaignInstructionsOnlineReducer,
  saveCampaignInstructionsOnline: saveCampaignInstructionsOnlineReducer,
  customer: combineReducers({
    accounts: receiveCustomerAccountsReducer,
    opportunities: receiveCustomerOpportunitiesReducer,
    contacts: receiveCustomerContactsReducer,
  }),
  calculation: receiveCalculationReducer,
  relatedCampaigns: requestRelatedCampaignsReducer,
  editableCampaigns: receiveEditableCampaignReducer,
  admin: combineReducers({
    editableSettings: getEditableSettingsReducer,
    updateSetting: updateEditableSettingReducer,
  }),
  campaignAnalysisNmoNotification: getAnalysisNmoNotificationReducer,
  analysisUploadFile: analysisUploadFileReducer,
  spotTracker: combineReducers({
    data: receiveSpotTrackerReducer,
    export: exportSpotTrackerReducer,
  }),
  tips: tipsReducer,
});
