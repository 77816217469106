/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  OrderStatus,
  OrderStatusFromJSON,
  OrderStatusFromJSONTyped,
  OrderStatusToJSON,
} from "./";

/**
 *
 * @export
 * @interface SubOrderListItem
 */
export interface SubOrderListItem {
  /**
   *
   * @type {number}
   * @memberof SubOrderListItem
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof SubOrderListItem
   */
  orderId: number;
  /**
   *
   * @type {Date}
   * @memberof SubOrderListItem
   */
  startDate: Date;
  /**
   *
   * @type {Date}
   * @memberof SubOrderListItem
   */
  endDate: Date;
  /**
   *
   * @type {OrderStatus}
   * @memberof SubOrderListItem
   */
  status: OrderStatus;
  /**
   *
   * @type {string}
   * @memberof SubOrderListItem
   */
  campaignName?: string | null;
  /**
   *
   * @type {Array<number>}
   * @memberof SubOrderListItem
   */
  spotLength?: Array<number> | null;
  /**
   *
   * @type {string}
   * @memberof SubOrderListItem
   */
  packageDescription: string;
  /**
   *
   * @type {string}
   * @memberof SubOrderListItem
   */
  targetGroup: string;
  /**
   *
   * @type {number}
   * @memberof SubOrderListItem
   */
  requestedBudget?: number;
  /**
   *
   * @type {number}
   * @memberof SubOrderListItem
   */
  requestedGrp?: number;
  /**
   *
   * @type {number}
   * @memberof SubOrderListItem
   */
  bookedBudget?: number;
  /**
   *
   * @type {number}
   * @memberof SubOrderListItem
   */
  bookedGrp?: number;
}

export function SubOrderListItemFromJSON(json: any): SubOrderListItem {
  return SubOrderListItemFromJSONTyped(json, false);
}

export function SubOrderListItemFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SubOrderListItem {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    orderId: json["orderId"],
    startDate: new Date(json["startDate"]),
    endDate: new Date(json["endDate"]),
    status: OrderStatusFromJSON(json["status"]),
    campaignName: !exists(json, "campaignName")
      ? undefined
      : json["campaignName"],
    spotLength: !exists(json, "spotLength") ? undefined : json["spotLength"],
    packageDescription: json["packageDescription"],
    targetGroup: json["targetGroup"],
    requestedBudget: !exists(json, "requestedBudget")
      ? undefined
      : json["requestedBudget"],
    requestedGrp: !exists(json, "requestedGrp")
      ? undefined
      : json["requestedGrp"],
    bookedBudget: !exists(json, "bookedBudget")
      ? undefined
      : json["bookedBudget"],
    bookedGrp: !exists(json, "bookedGrp") ? undefined : json["bookedGrp"],
  };
}

export function SubOrderListItemToJSON(value?: SubOrderListItem | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    orderId: value.orderId,
    startDate: value.startDate.toISOString(),
    endDate: value.endDate.toISOString(),
    status: OrderStatusToJSON(value.status),
    campaignName: value.campaignName,
    spotLength: value.spotLength,
    packageDescription: value.packageDescription,
    targetGroup: value.targetGroup,
    requestedBudget: value.requestedBudget,
    requestedGrp: value.requestedGrp,
    bookedBudget: value.bookedBudget,
    bookedGrp: value.bookedGrp,
  };
}
