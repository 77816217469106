import { MessageDescriptor } from "@lingui/core";
import { msg, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form, VisibleChangeFunction } from "@ster/ster-toolkit";
import { Form as AntForm, Modal, Select, Spin, Tooltip } from "antd";
import { Store } from "antd/lib/form/interface";
import Cookies from "js-cookie";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PortalUser } from "../api";
import { receiveAdvertisersAction } from "../store/advertisers/actions";
import { StoreModel } from "../store/models";
import { receiveOrganisationsAction } from "../store/organisations/actions";
import { defaultCookieOptions, getOrganisationCode } from "../utils";
import {
  advertiserCookieName,
  impersonateCookieName,
  klantportalAdverteerder,
  organisationCookieName,
} from "../utils/constants";

export const roleLabel: Record<string, MessageDescriptor> = {
  user: msg`Gebruiker`,
  intermediair: msg`Intermediair`,
};

const Impersonate = ({
  loggedIn,
  account,
  onVisibleChange,
}: {
  loggedIn: boolean;
  account: PortalUser;
  onVisibleChange: VisibleChangeFunction;
}) => {
  const { i18n } = useLingui();
  const internalUser = useMemo(
    () => Boolean(loggedIn && account && account.sterInlog?.internalUser),
    [account, loggedIn]
  );
  const impersonate = useMemo(
    () =>
      Boolean(
        loggedIn &&
          account &&
          ["user", "intermediair"].includes(
            Cookies.get(impersonateCookieName) ?? ""
          )
      ),
    [account, loggedIn]
  );

  const [form] = AntForm.useForm();
  const [show, setShow] = useState(false);

  /* Show dialog with form to impersonate */
  const handleImpersonate = useCallback(() => {
    onVisibleChange(false);
    setShow(true);
  }, [onVisibleChange]);

  /* Reset impersonate cookies */
  const handleReset = useCallback(() => {
    onVisibleChange(false);

    // Reset values to default
    Cookies.remove(impersonateCookieName);
    Cookies.remove(organisationCookieName);
    Cookies.remove(advertiserCookieName);

    // Reload page
    window.location.reload();
  }, [onVisibleChange]);

  const handleOk = useCallback(() => {
    form.submit();
  }, [form]);
  const handleCancel = useCallback(() => {
    form.resetFields();
    setShow(false);
  }, [form]);

  const handleFinish = useCallback((values: Store) => {
    setShow(false);
    if (!values) {
      return;
    }

    // Set cookies
    Cookies.set(impersonateCookieName, values.role, defaultCookieOptions);
    Cookies.set(
      organisationCookieName,
      values.organisation,
      defaultCookieOptions
    );

    if (values.organisation === klantportalAdverteerder) {
      Cookies.set(
        advertiserCookieName,
        values.advertiser,
        defaultCookieOptions
      );
    } else {
      Cookies.remove(advertiserCookieName, defaultCookieOptions);
    }

    // Reload page
    window.location.reload();
  }, []);

  /* Load a list of organisations */
  const dispatch = useDispatch();
  useEffect(() => {
    if (internalUser && show) {
      dispatch(receiveOrganisationsAction.request());
      dispatch(receiveAdvertisersAction.request());
    }
  }, [dispatch, internalUser, show]);

  const { organisations = [], loading: loadingOrganisations } = useSelector(
    (store: StoreModel) => store.organisations
  );

  const { advertisers = [], loading: loadingAdvertisers } = useSelector(
    (store: StoreModel) => store.advertisers
  );

  if (!internalUser && !impersonate) {
    return null;
  }

  return (
    <>
      {impersonate &&
        roleLabel[Cookies.get(impersonateCookieName) as string] && (
          <Tooltip title={i18n._(t`Rol en organisatie resetten?`)}>
            <button
              type="button"
              className="link context-menu__link"
              onClick={handleReset}
            >
              {i18n._(t`Rol`)}:{" "}
              {i18n._(roleLabel[Cookies.get(impersonateCookieName) as string])}{" "}
              ({getOrganisationCode(account)})
            </button>
          </Tooltip>
        )}
      {internalUser && !impersonate && (
        <button
          type="button"
          className="link context-menu__link"
          onClick={handleImpersonate}
        >
          {i18n._(t`Rol aanpassen`)}
        </button>
      )}
      {show && (
        <Modal
          open
          title={i18n._(t`Rol aanpassen naar`)}
          onOk={handleOk}
          onCancel={handleCancel}
          maskClosable={false}
        >
          <Spin spinning={loadingOrganisations || loadingAdvertisers}>
            <Form
              layout="vertical"
              name="impersonateForm"
              form={form}
              onFinish={handleFinish}
              initialValues={{ organisation: getOrganisationCode(account) }}
            >
              <Form.Item
                label={i18n._(t`Rol`)}
                name="role"
                rules={[{ required: true }]}
              >
                <Select
                  options={[
                    { label: i18n._(roleLabel.user), value: "user" },
                    {
                      label: i18n._(roleLabel.intermediair),
                      value: "intermediair",
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label={i18n._(t`Organisatie`)}
                name="organisation"
                rules={[{ required: true }]}
                dependencies={["advertiser"]}
              >
                <Select
                  options={organisations.map((o) => ({
                    label: o.name,
                    value: o.code,
                  }))}
                  disabled={organisations.length === 0}
                  showSearch
                  optionFilterProp="label"
                />
              </Form.Item>
              <Form.Item noStyle dependencies={["organisation"]}>
                {({ getFieldValue }) => {
                  const isKpAdvertiser =
                    getFieldValue("organisation") === klantportalAdverteerder;
                  return (
                    isKpAdvertiser && (
                      <Form.Item
                        name="advertiser"
                        label={i18n._(t`Adverteerder`)}
                        rules={[{ required: true }]}
                        dependencies={["organisation"]}
                      >
                        <Select
                          options={advertisers.map((a) => ({
                            label: a.name,
                            value: a.id,
                          }))}
                          disabled={advertisers.length === 0 || !isKpAdvertiser}
                          showSearch
                          optionFilterProp="label"
                          placeholder={i18n._(t`Zoek`)}
                        />
                      </Form.Item>
                    )
                  );
                }}
              </Form.Item>
            </Form>
          </Spin>
        </Modal>
      )}
    </>
  );
};

export default Impersonate;
