/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { Age, AgeFromJSON, AgeFromJSONTyped, AgeToJSON } from "./";

/**
 *
 * @export
 * @interface AnalysisTargetGroupTV
 */
export interface AnalysisTargetGroupTV {
  /**
   *
   * @type {boolean}
   * @memberof AnalysisTargetGroupTV
   */
  dag?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AnalysisTargetGroupTV
   */
  zzp?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AnalysisTargetGroupTV
   */
  kND12?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AnalysisTargetGroupTV
   */
  kND17?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AnalysisTargetGroupTV
   */
  hhv?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AnalysisTargetGroupTV
   */
  aB1?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AnalysisTargetGroupTV
   */
  ehb?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AnalysisTargetGroupTV
   */
  w1?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AnalysisTargetGroupTV
   */
  b2B?: boolean;
  /**
   *
   * @type {Age}
   * @memberof AnalysisTargetGroupTV
   */
  age: Age;
  /**
   *
   * @type {string}
   * @memberof AnalysisTargetGroupTV
   */
  gender?: string | null;
}

export function AnalysisTargetGroupTVFromJSON(
  json: any
): AnalysisTargetGroupTV {
  return AnalysisTargetGroupTVFromJSONTyped(json, false);
}

export function AnalysisTargetGroupTVFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AnalysisTargetGroupTV {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    dag: !exists(json, "dag") ? undefined : json["dag"],
    zzp: !exists(json, "zzp") ? undefined : json["zzp"],
    kND12: !exists(json, "+KND12") ? undefined : json["+KND12"],
    kND17: !exists(json, "+KND17") ? undefined : json["+KND17"],
    hhv: !exists(json, "hhv") ? undefined : json["hhv"],
    aB1: !exists(json, "aB1") ? undefined : json["aB1"],
    ehb: !exists(json, "ehb") ? undefined : json["ehb"],
    w1: !exists(json, "w1") ? undefined : json["w1"],
    b2B: !exists(json, "b2B") ? undefined : json["b2B"],
    age: AgeFromJSON(json["age"]),
    gender: !exists(json, "gender") ? undefined : json["gender"],
  };
}

export function AnalysisTargetGroupTVToJSON(
  value?: AnalysisTargetGroupTV | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    dag: value.dag,
    zzp: value.zzp,
    "+KND12": value.kND12,
    "+KND17": value.kND17,
    hhv: value.hhv,
    aB1: value.aB1,
    ehb: value.ehb,
    w1: value.w1,
    b2B: value.b2B,
    age: AgeToJSON(value.age),
    gender: value.gender,
  };
}
