/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  CampaignConfiguration,
  CampaignConfigurationFromJSON,
  CampaignConfigurationInput,
  CampaignConfigurationInputFromJSON,
  CampaignConfigurationInputToJSON,
  CampaignConfigurationToJSON,
  CampaignImportOutput,
  CampaignImportOutputFromJSON,
  CampaignImportOutputToJSON,
  ExportOutputSettings,
  ExportOutputSettingsFromJSON,
  ExportOutputSettingsToJSON,
  MediumEnum,
  MediumEnumFromJSON,
  MediumEnumToJSON,
  OrderDetail,
  OrderDetailFromJSON,
  OrderDetailToJSON,
  OrderListItem,
  OrderListItemFromJSON,
  OrderListItemToJSON,
  OrderRequest,
  OrderRequestFromJSON,
  OrderRequestToJSON,
  ProblemDetails,
  ProblemDetailsFromJSON,
  ProblemDetailsToJSON,
  SpotsByMedium,
  SpotsByMediumFromJSON,
  SpotsByMediumToJSON,
  SpotsResult,
  SpotsResultFromJSON,
  SpotsResultToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface ApiV1CampaignCodeCampaignCodeGetRequest {
  campaignCode: string;
}

export interface ApiV1CampaignCodePostRequest {
  campaignConfigurationInput: CampaignConfigurationInput;
}

export interface ApiV1CampaignEndreportMediumOrderIdSubOrderIdFileTypeGetRequest {
  medium: MediumEnum;
  orderId: number;
  subOrderId: number;
  fileType: string;
}

export interface ApiV1CampaignExportPostRequest {
  exportOutputSettings: ExportOutputSettings;
  medium: MediumEnum;
}

export interface ApiV1CampaignGeteditablecampaignGetRequest {
  orderId: number;
  medium?: MediumEnum;
}

export interface ApiV1CampaignGetorderGetRequest {
  medium?: MediumEnum;
  orderId?: number;
}

export interface ApiV1CampaignGetordersGetRequest {
  from?: Date;
  to?: Date;
}

export interface ApiV1CampaignGetorderwithspotsGetRequest {
  medium?: MediumEnum;
  orderId?: number;
}

export interface ApiV1CampaignGetspotsbyorderGetRequest {
  medium?: MediumEnum;
  orderId?: number;
  secondaryTargetGroupId?: string;
}

export interface ApiV1CampaignUpdatePostRequest {
  orderRequest?: OrderRequest;
}

/**
 *
 */
export class CampaignApi extends runtime.BaseAPI {
  /**
   */
  async apiV1CampaignCodeCampaignCodeGetRaw(
    requestParameters: ApiV1CampaignCodeCampaignCodeGetRequest
  ): Promise<runtime.ApiResponse<CampaignConfiguration>> {
    if (
      requestParameters.campaignCode === null ||
      requestParameters.campaignCode === undefined
    ) {
      throw new runtime.RequiredError(
        "campaignCode",
        "Required parameter requestParameters.campaignCode was null or undefined when calling apiV1CampaignCodeCampaignCodeGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Campaign/code/{campaignCode}`.replace(
        `{${"campaignCode"}}`,
        encodeURIComponent(String(requestParameters.campaignCode))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CampaignConfigurationFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1CampaignCodeCampaignCodeGet(
    requestParameters: ApiV1CampaignCodeCampaignCodeGetRequest
  ): Promise<CampaignConfiguration> {
    const response =
      await this.apiV1CampaignCodeCampaignCodeGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1CampaignCodePostRaw(
    requestParameters: ApiV1CampaignCodePostRequest
  ): Promise<runtime.ApiResponse<CampaignImportOutput>> {
    if (
      requestParameters.campaignConfigurationInput === null ||
      requestParameters.campaignConfigurationInput === undefined
    ) {
      throw new runtime.RequiredError(
        "campaignConfigurationInput",
        "Required parameter requestParameters.campaignConfigurationInput was null or undefined when calling apiV1CampaignCodePost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Campaign/code`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: CampaignConfigurationInputToJSON(
        requestParameters.campaignConfigurationInput
      ),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CampaignImportOutputFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1CampaignCodePost(
    requestParameters: ApiV1CampaignCodePostRequest
  ): Promise<CampaignImportOutput> {
    const response = await this.apiV1CampaignCodePostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1CampaignEndreportMediumOrderIdSubOrderIdFileTypeGetRaw(
    requestParameters: ApiV1CampaignEndreportMediumOrderIdSubOrderIdFileTypeGetRequest
  ): Promise<runtime.ApiResponse<string>> {
    if (
      requestParameters.medium === null ||
      requestParameters.medium === undefined
    ) {
      throw new runtime.RequiredError(
        "medium",
        "Required parameter requestParameters.medium was null or undefined when calling apiV1CampaignEndreportMediumOrderIdSubOrderIdFileTypeGet."
      );
    }

    if (
      requestParameters.orderId === null ||
      requestParameters.orderId === undefined
    ) {
      throw new runtime.RequiredError(
        "orderId",
        "Required parameter requestParameters.orderId was null or undefined when calling apiV1CampaignEndreportMediumOrderIdSubOrderIdFileTypeGet."
      );
    }

    if (
      requestParameters.subOrderId === null ||
      requestParameters.subOrderId === undefined
    ) {
      throw new runtime.RequiredError(
        "subOrderId",
        "Required parameter requestParameters.subOrderId was null or undefined when calling apiV1CampaignEndreportMediumOrderIdSubOrderIdFileTypeGet."
      );
    }

    if (
      requestParameters.fileType === null ||
      requestParameters.fileType === undefined
    ) {
      throw new runtime.RequiredError(
        "fileType",
        "Required parameter requestParameters.fileType was null or undefined when calling apiV1CampaignEndreportMediumOrderIdSubOrderIdFileTypeGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Campaign/endreport/{medium}/{orderId}/{subOrderId}/{fileType}`
        .replace(
          `{${"medium"}}`,
          encodeURIComponent(String(requestParameters.medium))
        )
        .replace(
          `{${"orderId"}}`,
          encodeURIComponent(String(requestParameters.orderId))
        )
        .replace(
          `{${"subOrderId"}}`,
          encodeURIComponent(String(requestParameters.subOrderId))
        )
        .replace(
          `{${"fileType"}}`,
          encodeURIComponent(String(requestParameters.fileType))
        ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   */
  async apiV1CampaignEndreportMediumOrderIdSubOrderIdFileTypeGet(
    requestParameters: ApiV1CampaignEndreportMediumOrderIdSubOrderIdFileTypeGetRequest
  ): Promise<string> {
    const response =
      await this.apiV1CampaignEndreportMediumOrderIdSubOrderIdFileTypeGetRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   */
  async apiV1CampaignExportPostRaw(
    requestParameters: ApiV1CampaignExportPostRequest
  ): Promise<runtime.ApiResponse<Blob>> {
    if (
      requestParameters.exportOutputSettings === null ||
      requestParameters.exportOutputSettings === undefined
    ) {
      throw new runtime.RequiredError(
        "exportOutputSettings",
        "Required parameter requestParameters.exportOutputSettings was null or undefined when calling apiV1CampaignExportPost."
      );
    }

    if (
      requestParameters.medium === null ||
      requestParameters.medium === undefined
    ) {
      throw new runtime.RequiredError(
        "medium",
        "Required parameter requestParameters.medium was null or undefined when calling apiV1CampaignExportPost."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.medium !== undefined) {
      queryParameters["medium"] = requestParameters.medium;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Campaign/export`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ExportOutputSettingsToJSON(requestParameters.exportOutputSettings),
    });

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async apiV1CampaignExportPost(
    requestParameters: ApiV1CampaignExportPostRequest
  ): Promise<Blob> {
    const response = await this.apiV1CampaignExportPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1CampaignGeteditablecampaignGetRaw(
    requestParameters: ApiV1CampaignGeteditablecampaignGetRequest
  ): Promise<runtime.ApiResponse<OrderRequest>> {
    if (
      requestParameters.orderId === null ||
      requestParameters.orderId === undefined
    ) {
      throw new runtime.RequiredError(
        "orderId",
        "Required parameter requestParameters.orderId was null or undefined when calling apiV1CampaignGeteditablecampaignGet."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.medium !== undefined) {
      queryParameters["medium"] = requestParameters.medium;
    }

    if (requestParameters.orderId !== undefined) {
      queryParameters["orderId"] = requestParameters.orderId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Campaign/geteditablecampaign`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OrderRequestFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1CampaignGeteditablecampaignGet(
    requestParameters: ApiV1CampaignGeteditablecampaignGetRequest
  ): Promise<OrderRequest> {
    const response =
      await this.apiV1CampaignGeteditablecampaignGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1CampaignGetorderGetRaw(
    requestParameters: ApiV1CampaignGetorderGetRequest
  ): Promise<runtime.ApiResponse<OrderDetail>> {
    const queryParameters: any = {};

    if (requestParameters.medium !== undefined) {
      queryParameters["medium"] = requestParameters.medium;
    }

    if (requestParameters.orderId !== undefined) {
      queryParameters["orderId"] = requestParameters.orderId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Campaign/getorder`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OrderDetailFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1CampaignGetorderGet(
    requestParameters: ApiV1CampaignGetorderGetRequest
  ): Promise<OrderDetail> {
    const response = await this.apiV1CampaignGetorderGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1CampaignGetordersGetRaw(
    requestParameters: ApiV1CampaignGetordersGetRequest
  ): Promise<runtime.ApiResponse<Array<OrderListItem>>> {
    const queryParameters: any = {};

    if (requestParameters.from !== undefined) {
      queryParameters["from"] = (requestParameters.from as any).toISOString();
    }

    if (requestParameters.to !== undefined) {
      queryParameters["to"] = (requestParameters.to as any).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Campaign/getorders`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(OrderListItemFromJSON)
    );
  }

  /**
   */
  async apiV1CampaignGetordersGet(
    requestParameters: ApiV1CampaignGetordersGetRequest
  ): Promise<Array<OrderListItem>> {
    const response = await this.apiV1CampaignGetordersGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1CampaignGetorderwithspotsGetRaw(
    requestParameters: ApiV1CampaignGetorderwithspotsGetRequest
  ): Promise<runtime.ApiResponse<OrderDetail>> {
    const queryParameters: any = {};

    if (requestParameters.medium !== undefined) {
      queryParameters["medium"] = requestParameters.medium;
    }

    if (requestParameters.orderId !== undefined) {
      queryParameters["orderId"] = requestParameters.orderId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Campaign/getorderwithspots`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OrderDetailFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1CampaignGetorderwithspotsGet(
    requestParameters: ApiV1CampaignGetorderwithspotsGetRequest
  ): Promise<OrderDetail> {
    const response =
      await this.apiV1CampaignGetorderwithspotsGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1CampaignGetspotsGetRaw(): Promise<
    runtime.ApiResponse<Array<SpotsByMedium>>
  > {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Campaign/getspots`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(SpotsByMediumFromJSON)
    );
  }

  /**
   */
  async apiV1CampaignGetspotsGet(): Promise<Array<SpotsByMedium>> {
    const response = await this.apiV1CampaignGetspotsGetRaw();
    return await response.value();
  }

  /**
   */
  async apiV1CampaignGetspotsbyorderGetRaw(
    requestParameters: ApiV1CampaignGetspotsbyorderGetRequest
  ): Promise<runtime.ApiResponse<SpotsResult>> {
    const queryParameters: any = {};

    if (requestParameters.medium !== undefined) {
      queryParameters["medium"] = requestParameters.medium;
    }

    if (requestParameters.orderId !== undefined) {
      queryParameters["orderId"] = requestParameters.orderId;
    }

    if (requestParameters.secondaryTargetGroupId !== undefined) {
      queryParameters["secondaryTargetGroupId"] =
        requestParameters.secondaryTargetGroupId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Campaign/getspotsbyorder`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SpotsResultFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1CampaignGetspotsbyorderGet(
    requestParameters: ApiV1CampaignGetspotsbyorderGetRequest
  ): Promise<SpotsResult> {
    const response =
      await this.apiV1CampaignGetspotsbyorderGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1CampaignUpdatePostRaw(
    requestParameters: ApiV1CampaignUpdatePostRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Campaign/update`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: OrderRequestToJSON(requestParameters.orderRequest),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async apiV1CampaignUpdatePost(
    requestParameters: ApiV1CampaignUpdatePostRequest
  ): Promise<void> {
    await this.apiV1CampaignUpdatePostRaw(requestParameters);
  }
}
