import { i18n } from "@lingui/core";
import { Trans, t } from "@lingui/macro";
import { Button, ContentContainer, Row, Spinner } from "@ster/ster-toolkit";
import { App as AntApp, Col, Space } from "antd";
import { useForm } from "antd/lib/form/Form";
import { memo, useCallback, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { UserModel } from "../../api";
import { StoreModel } from "../../store/models";
import {
  clearUserAction,
  createUserAction,
  receiveUserAction,
  updateUserAction,
} from "../../store/users/actions";
import shared from "../Shared.module.less";
import { UsersFilter } from "./models";
import { usersRootSelector } from "./selectors";
import UserForm from "./UserForm";

const UserDetailContainer = memo(
  ({ setFilter }: { setFilter: (value: UsersFilter) => void }) => {
    const dispatch = useDispatch();
    const { users, organisations, advertisers, settings, loading } =
      useSelector(usersRootSelector);
    const { message } = AntApp.useApp();
    const navigate = useNavigate();
    const [form] = useForm();
    const { userId } = useParams<{ userId?: string }>();
    const isEditAction = useMemo(() => !!userId, [userId]);
    useEffect(() => {
      if (userId && isEditAction) {
        dispatch(receiveUserAction.request(userId));
      } else {
        form.resetFields();
      }
    }, [dispatch, form, isEditAction, userId]);

    const { loadingSingleUser = false, userToEdit } = useSelector(
      ({ user }: StoreModel) => ({
        loadingSingleUser: user.loading,
        userToEdit: { ...user },
      })
    );

    const handleCancelUser = useCallback(() => {
      form.resetFields();
      dispatch(clearUserAction);
      navigate("/admin/users");
    }, [dispatch, form, navigate]);

    const handleSubmitUser = useCallback(() => {
      form.submit();
    }, [form]);

    const validateEmailaddress = useCallback(
      (email: string): boolean =>
        users.some((u) => u.email.toLowerCase() === email.toLowerCase()),
      [users]
    );

    const handleSaveUser = useCallback(
      (user: UserModel) => {
        if (isEditAction) {
          // bewerken
          dispatch(
            updateUserAction.request({
              userId: userId as string,
              userModel: user,
            })
          );
        } else {
          // aanmaken
          dispatch(createUserAction.request(user));
        }

        form.resetFields();
        navigate("/admin/users");
        const msg = i18n._(t`Gebruiker ${user.email} is opgeslagen`);
        message.success(msg);
        setFilter({
          search: user.email,
          organisationCode: user.organisationCode,
        });
      },
      [dispatch, form, isEditAction, message, navigate, setFilter, userId]
    );

    return (
      <>
        <Helmet>
          <title>{i18n._(t`Gebruikers`)}</title>
        </Helmet>

        <ContentContainer>
          <Spinner spinning={loading || loadingSingleUser}>
            <Row
              className={shared.filterrow}
              justify="end"
              align="middle"
              gutter={[24, 24]}
            >
              <Col span={24}>
                <Space className={shared.filterspacer} direction="horizontal">
                  <Button mode="secondary" onClick={handleCancelUser}>
                    <Trans>Annuleren</Trans>
                  </Button>
                  <Button
                    htmlType="submit"
                    mode="primary"
                    onClick={handleSubmitUser}
                  >
                    <Trans>Opslaan</Trans>
                  </Button>
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <UserForm
                  form={form}
                  onInValidEmail={validateEmailaddress}
                  onSave={handleSaveUser}
                  organisations={organisations}
                  advertisers={advertisers}
                  settings={settings}
                  user={userToEdit}
                  isEditAction={isEditAction}
                />
              </Col>
            </Row>
          </Spinner>
        </ContentContainer>
      </>
    );
  }
);

export default UserDetailContainer;
