import { createSelector, createStructuredSelector } from "reselect";

import { ForecastResult } from "../../api";
import {
  accountSelector,
  createStructuredAppSelector,
} from "../../shared/selectors";
import { Loading, ReduxStoreState } from "../../store/base";
import {
  LoadingCampaigns,
  LoadingForecastsForProposal,
  StoreModel,
} from "../../store/models";
import { campaignListSelector } from "../campaigns/selectors";

const forecastsForProposalFromStoreSelector = ({
  forecastsForProposal: forecastsForProposalFromStore,
}: StoreModel): LoadingForecastsForProposal => forecastsForProposalFromStore;

const campaignFromStoreSelector = ({
  campaigns: campaignsFromStore,
}: StoreModel): LoadingCampaigns => campaignsFromStore;

const forecastForProposalSelector = createSelector(
  [forecastsForProposalFromStoreSelector],
  (
    forecastsForProposal: LoadingForecastsForProposal
  ): Record<string, ForecastResult> => forecastsForProposal.forecasts
);

const proposalSelector = ({
  proposalGenerate: { state },
}: StoreModel): ReduxStoreState => state;

const proposalUploadForecastFromStoreSelector = ({
  proposalUploadForecast: proposalUploadForecastFromStore,
}: StoreModel): Loading => proposalUploadForecastFromStore;

const analysisUploadFileFromStoreSelector = ({
  analysisUploadFile: analysisUploadFileFromStore,
}: StoreModel): Loading => analysisUploadFileFromStore;

const loadingGenerateFormattedDocumentSelector = createSelector(
  [
    proposalUploadForecastFromStoreSelector,
    analysisUploadFileFromStoreSelector,
  ],
  (proposalUploadForecastLoading, analysisUploadFileLoading): boolean =>
    proposalUploadForecastLoading.loading || analysisUploadFileLoading.loading
);

export const loadingSelector = createSelector(
  [forecastsForProposalFromStoreSelector, campaignFromStoreSelector],
  (
    forecastsForProposal: LoadingForecastsForProposal,
    campaigns: LoadingCampaigns
  ): boolean =>
    (forecastsForProposal.loading ?? false) || (campaigns.loading ?? false)
);

export const proposalRootSelector = createStructuredSelector({
  loading: loadingSelector,
  forecasts: forecastForProposalSelector,
  campaignsData: campaignListSelector,
});

export const proposalRequestSelector = createStructuredAppSelector({
  proposalState: proposalSelector,
  account: accountSelector,
});

export const generateFormattedDocumentRootSelector =
  createStructuredAppSelector({
    loading: loadingGenerateFormattedDocumentSelector,
  });
