import { add, endOfMonth, startOfMonth, sub } from "date-fns";
import { createSelector, createStructuredSelector } from "reselect";

import {
  Advertiser,
  AnnouncementDto,
  MediumEnum,
  OrderRequest,
  Organisation,
  SecondaryTargetGroup,
  SettingsResult,
} from "../api";
import {
  showTermsSelector,
  termsDateSelector,
} from "../components/terms/selectors";
import { disabledClaims } from "../components/users/UserForm";
import { Loading, ReduxStoreState } from "../store/base";
import {
  LoadingAdvertisers,
  LoadingBookingDate,
  LoadingInitialRequest,
  LoadingOrganisations,
  LoadingPackages,
  LoadingProductTourResult,
  LoadingSalesPeriods,
  SecondaryTargetGroups,
  StoreModel,
  TenantContext,
} from "../store/models";
import { getOrganisationCode, isNumber } from "../utils";
import { emptyArray, klantportalAdverteerder } from "../utils/constants";
import { Period } from "./models";

export const createStructuredAppSelector =
  createStructuredSelector.withTypes<StoreModel>();

export interface RelativePeriod {
  inThePast: number;
  inTheFuture: number;
}

export const settingsSelector = (state: StoreModel): SettingsResult =>
  state.settings;

export const defaultRelativePeriodSelector = createSelector(
  [settingsSelector],
  (settings) => {
    let inThePast = 2;
    let inTheFuture = 2;
    if (settings.settings) {
      const {
        months_in_the_past: pastFromSettings,
        months_in_the_future: futureFromSettings,
      } = settings.settings as Record<string, unknown>;
      inThePast = pastFromSettings as unknown as number;
      inTheFuture = futureFromSettings as unknown as number;
    }

    return { inThePast, inTheFuture };
  }
);

export const initialDatesCombiner = (period: RelativePeriod): Period => {
  const now = new Date();
  const past = sub(now, { months: period.inThePast });
  const future = add(now, { months: period.inTheFuture });

  return {
    from: startOfMonth(past),
    to: endOfMonth(future),
  };
};

const initialFromDateSelector = createSelector(
  [defaultRelativePeriodSelector],
  (period) => initialDatesCombiner(period).from
);
const initialToDateSelector = createSelector(
  [defaultRelativePeriodSelector],
  (period) => initialDatesCombiner(period).to
);
/**
 * Selector to create a new object with a date-from and -to property
 * based on the initial settings from the API
 */
export const initialDatesSelector = createStructuredSelector({
  from: initialFromDateSelector,
  to: initialToDateSelector,
});

/**
 * Selector to create a new period of the current month
 */
export const previousMonthSelector = (): Period => {
  const now = new Date();
  const prevMonth = sub(now, { months: 1 });
  return {
    from: startOfMonth(prevMonth),
    to: endOfMonth(prevMonth),
  };
};

/**
 * Selector for getting the `LoadingOrganisations` object
 */
export const organisationsFromStoreSelector = ({
  organisations: organisationsFromStore,
}: StoreModel): LoadingOrganisations => organisationsFromStore;

/**
 * Selector for getting an array of `Organisation` objects
 */
export const organisationsSelector = createSelector(
  [organisationsFromStoreSelector],
  (organisations: LoadingOrganisations): Organisation[] =>
    organisations.organisations ?? []
);

/**
 * Selector for getting the loading state of the organisations
 */
export const organisationsLoadingSelector = createSelector(
  [organisationsFromStoreSelector],
  (organisations: LoadingOrganisations): boolean =>
    organisations.loading ?? false
);

/**
 * Selector for getting the `LoadingAdvertisers` object
 */
export const advertisersFromStoreSelector = ({
  advertisers: advertisersFromStore,
}: StoreModel): LoadingAdvertisers => advertisersFromStore;

/**
 * Selector for getting an array of `Advertisers` objects
 */
export const advertisersSelector = createSelector(
  [advertisersFromStoreSelector],
  (advertisers: LoadingAdvertisers): Advertiser[] =>
    advertisers.advertisers ?? []
);

/**
 * Selector for getting the loading state of the advertisers
 */
export const advertisersLoadingSelector = createSelector(
  [advertisersFromStoreSelector],
  (advertisers: LoadingAdvertisers): boolean => advertisers.loading ?? false
);

const accountFromStoreSelector = (state: StoreModel) => state.account;

export const accountSelector = createSelector(
  [accountFromStoreSelector],
  (accountInStore) => ({
    ...accountInStore,
    claims:
      accountInStore?.sterInlog?.internalUser &&
      getOrganisationCode(accountInStore) === klantportalAdverteerder
        ? accountInStore.claims.filter((c) => !disabledClaims.includes(c)) // Filter de claims als de gebruiker een ster medewerker is en is inlogd als 'Klantportal Adverteerder'
        : accountInStore.claims,
  })
);

export const tenantContextSelector = (state: StoreModel): TenantContext =>
  state.tenantContext;

export const availableTenantsSelector = createSelector(
  [accountFromStoreSelector],
  (account) => account?.userInfo?.tenants
);

export const packagesFromStoreSelector = ({
  packages,
}: StoreModel): LoadingPackages => packages;

export const packagesSelector = createSelector(
  [packagesFromStoreSelector],
  (packages: LoadingPackages) => packages.packages || []
);

export const secondaryTargetGroupsSelector = ({
  secondaryTargetGroups,
}: StoreModel): SecondaryTargetGroups =>
  secondaryTargetGroups as SecondaryTargetGroups;

const emptySecondaryTargetGroups: SecondaryTargetGroup[] = [];

export const secondaryTargetGroupsSelectorByMedium = createSelector(
  [
    secondaryTargetGroupsSelector,
    (
      store: StoreModel,
      { medium }: { medium: MediumEnum } & unknown
    ): MediumEnum => medium,
  ],
  (
    secondaryTargetGroups: SecondaryTargetGroups,
    medium: MediumEnum
  ): SecondaryTargetGroup[] =>
    secondaryTargetGroups[medium] ?? emptySecondaryTargetGroups
);

export const bookingDateFromStoreSelector = ({
  bookingDate,
}: StoreModel): LoadingBookingDate => bookingDate;

export const salesPeriodsFromStoreSelector = ({
  salesPeriods,
}: StoreModel): LoadingSalesPeriods => salesPeriods;

export const productTourFromStoreSelector = ({
  productTour,
}: StoreModel): LoadingProductTourResult => productTour;

export const orderRequestSelector = (
  _: StoreModel,
  orderRequest: OrderRequest | undefined
): OrderRequest | undefined => orderRequest;

export const bookingDateSelector = createSelector(
  [bookingDateFromStoreSelector],
  (bookingDate: LoadingBookingDate) => bookingDate.bookingDate || []
);

export const productTourLastStepSelector = createSelector(
  [productTourFromStoreSelector],
  (productTour: LoadingProductTourResult) => productTour.lastStep
);

export const salesPeriodSelector = createSelector(
  [salesPeriodsFromStoreSelector],
  (salesPeriods: LoadingSalesPeriods) =>
    salesPeriods?.salesPeriods ?? emptyArray
);

export const calculationTimestampSelector = ({
  calculation: { timestamp },
}: StoreModel): number | undefined => timestamp;

const loadingAppSelector = createSelector(
  [
    ({ account }: StoreModel): boolean =>
      account.state !== ReduxStoreState.Initial &&
      account.state !== ReduxStoreState.Loading,
    ({ settings }: StoreModel): boolean =>
      settings.state !== ReduxStoreState.Initial &&
      settings.state !== ReduxStoreState.Loading,
    ({ terms }: StoreModel): boolean =>
      terms.state !== ReduxStoreState.Initial &&
      terms.state !== ReduxStoreState.Loading,
    ({ productTour }: StoreModel): boolean =>
      productTour.state !== ReduxStoreState.Initial &&
      productTour.state !== ReduxStoreState.Loading,
  ],
  (accountReady, settingsReady, termsReady, productTourReady) =>
    !accountReady && !settingsReady && !termsReady && !productTourReady
);

export interface SimpleState {
  propertyName: keyof StoreModel;
  state: ReduxStoreState;
}

const simpleStateSelector = createSelector(
  [(store: StoreModel): StoreModel => store, Object.keys],
  (store: StoreModel, keys: string[]) => {
    const result: SimpleState[] = [];
    keys.forEach((k) => {
      const key = k as keyof StoreModel;
      const obj = store[key] as Loading;
      if (obj.state) {
        result.push({
          propertyName: key,
          state: obj.state,
        });
      }

      const subKeys = Object.keys(obj);
      subKeys.forEach((subKey) => {
        if (!isNumber(subKey)) {
          return;
        }

        const idKey = Number.parseInt(subKey, 10);
        const subObj = (obj as unknown as Record<number, Loading>)[
          idKey
        ] as Loading;
        if (subObj.state) {
          result.push({
            propertyName: key,
            state: subObj.state,
          });
        }
      });
    });

    return result;
  }
);

const failureSelector = createSelector(
  [simpleStateSelector],
  (simpleStates: SimpleState[]) =>
    simpleStates.filter(({ state }) => state === ReduxStoreState.Failure)
);

/**
 * Structure for the `App` component
 */
export const appSelector = createStructuredAppSelector({
  account: accountSelector,
  settings: settingsSelector,
  showTerms: showTermsSelector,
  dateOfTerms: termsDateSelector,
  productTourLastStep: productTourLastStepSelector,
  loading: loadingAppSelector,
  failures: failureSelector,
});

const publishedAnnouncementSelector = ({
  publishedAnnouncements,
}: StoreModel): AnnouncementDto[] => publishedAnnouncements.announcements;

/**
 * Structure for the `Notification` component
 */
export const notificationSelector = createStructuredAppSelector({
  account: accountSelector,
  announcements: publishedAnnouncementSelector,
});

export const initialRequestDetailSelector = (
  { initialRequest }: StoreModel,
  from: Date,
  initialRequestId?: number
): LoadingInitialRequest | undefined =>
  initialRequest[`${initialRequestId ?? 0}_${from.getMonth()}`];
