import "./forecast.less";

import { LoadingOutlined } from "@ant-design/icons";
import { i18n } from "@lingui/core";
import { Trans, t } from "@lingui/macro";
import {
  Button,
  Divider,
  Icons,
  Option,
  Select,
  Tooltip,
  Typography,
  formatNumber,
} from "@ster/ster-toolkit";
import { SelectValue } from "antd/lib/select";
import { memo, useCallback, useMemo, useState } from "react";

import {
  Forecast as ForecastItem,
  ForecastResult,
  ForecastTargetGroup,
  MediumEnum,
} from "../../../api";

export interface ForecastProps {
  medium: MediumEnum;
  forecast: ForecastResult;
  loading: boolean;
  conversionGroups?: ForecastTargetGroup[];
  onAddConversionGroup?: (value: string) => void;
  onDeleteConversionGroup?: (value: string) => void;
}

const ForecastRow = memo(
  ({
    forecast,
    canDelete,
    onDeleteConversionGroup,
  }: {
    forecast: ForecastItem;
    canDelete?: boolean;
    onDeleteConversionGroup?: (value: string) => void;
  }) => {
    const handleDeleteConversionGroup = useCallback(() => {
      if (onDeleteConversionGroup) {
        onDeleteConversionGroup(forecast.targetGroup ?? "");
      }
    }, [forecast.targetGroup, onDeleteConversionGroup]);

    const formatNumberDropTrailingZeros = (
      number: number,
      decimals = 2
    ): string =>
      Intl.NumberFormat("nl-NL", {
        maximumFractionDigits: decimals,
      }).format(number);

    return (
      <div className="forecast-row">
        <div className="forecast-col">
          <span className="forecast-col__value">
            {forecast.targetGroupDisplayName}
          </span>
        </div>
        <div className="forecast-col">
          <span className="forecast-col__value">
            {formatNumberDropTrailingZeros(forecast.bereikPercentage, 2)}
          </span>
        </div>
        <div className="forecast-col">
          <span className="forecast-col__value">
            {formatNumber(forecast.bereikNumber ?? 0)}
          </span>
        </div>
        <div className="forecast-col">
          <span className="forecast-col__value">
            {formatNumberDropTrailingZeros(forecast.gcf)}X
          </span>
        </div>
        <div className="forecast-col">
          <span className="forecast-col__value">
            {formatNumberDropTrailingZeros(forecast.grp, 0)}
          </span>
        </div>
        {canDelete && onDeleteConversionGroup && (
          <div className="forecast-col delete">
            <span className="forecast-col__value">
              <span
                role="button"
                onClick={handleDeleteConversionGroup}
                tabIndex={0}
                aria-label={i18n._(t`Verwijder doelgroep`)}
                title={i18n._(t`Verwijder doelgroep`)}
              >
                <Icons.DeleteIcon fill="#fff" />
              </span>
            </span>
          </div>
        )}
      </div>
    );
  }
);

const Forecast = memo(
  ({
    medium,
    forecast: {
      forecasts,
      conversionForecasts,
      unknownPackageCodes,
      unknownChannels,
      yourTimeRequirementsNotMet,
      top2000RequirementsNotMet,
      regioChannelForecastNotAvailable,
      regioPackageForecastNotAvailable,
    },
    loading,
    conversionGroups,
    onAddConversionGroup,
    onDeleteConversionGroup,
  }: ForecastProps) => {
    const { AdultsIcon, CampaignIcon, EyeIcon, StatsIcon, GroupIcon } = Icons;
    const { Title } = Typography;

    const [selectedConversionGroup, setSelectedConversionGroup] = useState("");

    const handleChangeConversionGroup = useCallback((option: SelectValue) => {
      setSelectedConversionGroup(option as string);
    }, []);

    const handleAddConversionGroup = useCallback(() => {
      onAddConversionGroup?.(selectedConversionGroup);
    }, [onAddConversionGroup, selectedConversionGroup]);

    const showNoForecastAvailableMessage = useMemo(
      () => !forecasts || forecasts.length === 0,
      [forecasts]
    );

    return (
      <section className="forecast">
        <div className="forecast-title-wrapper">
          <Title level={3}>
            <Trans>Prognose</Trans>
          </Title>
        </div>
        <div className="forecast-header">
          <div className="forecast-col">
            <AdultsIcon width={40} height={40} fill="#fff" />
            <span className="forecast-col__stat">
              <Trans>Doelgroep</Trans>
            </span>
          </div>
          <div className="forecast-col">
            <CampaignIcon width={40} height={40} fill="#fff" />
            <span className="forecast-col__stat">
              <Trans>Bereik in %</Trans>
            </span>
          </div>
          <div className="forecast-col">
            <GroupIcon width={65} height={40} fill="#fff" />
            <span className="forecast-col__stat">
              <Trans>Bereik in aantal</Trans>
            </span>
          </div>
          <div className="forecast-col">
            <EyeIcon width={40} height={40} fill="#fff" />
            <span className="forecast-col__stat">
              <Trans>GCF</Trans>
              <Tooltip
                title={i18n._(
                  medium === MediumEnum.Tv
                    ? t`Doorgaans adviseren wij een gemiddelde contact frequentie (GCF) tussen de 3 en 5 contacten per campagne.`
                    : t`Doorgaans adviseren we voor een actiematige radio campagne een gemiddelde contact frequentie (GCF) van 5-7 keer. Voor campagnes met een naamsbekendheid doelstelling is dit 8-10 keer.`
                )}
                placement="bottom"
              >
                <span>
                  <Icons.QuestionIcon fill="white" width={16} height={16} />
                </span>
              </Tooltip>
            </span>
          </div>
          <div className="forecast-col">
            <StatsIcon width={40} height={40} fill="#fff" />
            <span className="forecast-col__stat">
              <Trans>Bruto bereik in % (GRP)</Trans>
            </span>
          </div>
        </div>
        <Divider className="forecast-divider" />
        {loading ? (
          <div className="forecast-row">
            <div className="forecast-col loading">
              <span className="forecast-col__value">
                <LoadingOutlined />
              </span>
            </div>
          </div>
        ) : (
          <>
            {yourTimeRequirementsNotMet || top2000RequirementsNotMet ? (
              <>
                {yourTimeRequirementsNotMet && medium === MediumEnum.Tv && (
                  <div className="forecast-row">
                    <Trans>
                      Voor een prognose op het Your Time TV-pakket of bij
                      specifieke inkoop dien je minimaal 25 GRP&apos;s te
                      selecteren
                    </Trans>
                  </div>
                )}
                {yourTimeRequirementsNotMet && medium === MediumEnum.Radio && (
                  <div className="forecast-row">
                    <Trans>
                      Voor een prognose op het Your Time radiopakket of bij
                      specifieke inkoop, dien je de aanvraag te combineren met
                      een GRP-inkoopoptie.
                    </Trans>
                  </div>
                )}
                {top2000RequirementsNotMet && medium === MediumEnum.Radio && (
                  <div className="forecast-row">
                    <Trans>
                      Op dit moment is het niet mogelijk een prognose te maken
                      waarin Top 2000 wordt gecombineerd met andere
                      inkoopopties.
                    </Trans>
                  </div>
                )}
              </>
            ) : (
              showNoForecastAvailableMessage && (
                <div className="forecast-row">
                  <Trans>Nog geen prognose beschikbaar</Trans>
                </div>
              )
            )}
            {forecasts?.map((forecast) => (
              <ForecastRow key={JSON.stringify(forecast)} forecast={forecast} />
            ))}
            {conversionForecasts?.map((forecast) => (
              <ForecastRow
                forecast={forecast}
                onDeleteConversionGroup={onDeleteConversionGroup}
                canDelete
                key={JSON.stringify(forecast)}
              />
            ))}
            {unknownPackageCodes && (unknownPackageCodes?.length ?? 0) > 0 && (
              <div className="forecast-row">
                <Trans>
                  Voor de volgende pakket(ten) is geen prognose beschikbaar:{" "}
                  {unknownPackageCodes.join(", ")}
                </Trans>
              </div>
            )}
            {unknownChannels &&
              (unknownChannels?.filter((s) =>
                regioChannelForecastNotAvailable ? s !== "TV Regio" : true
              ).length ?? 0) > 0 && (
                <div className="forecast-row">
                  <Trans>
                    Voor de volgende zender(s) is geen prognose beschikbaar:{" "}
                    {unknownChannels.join(", ")}
                  </Trans>
                </div>
              )}
            {regioChannelForecastNotAvailable && (
              <div className="forecast-row">
                <Trans>
                  Deze prognose gaat uit van het bereik exclusief het bereik op
                  de regionale omroepen. Zodra er voldoende data beschikbaar is
                  zal het bereik van de regionale omroepen toegevoegd worden.
                </Trans>
              </div>
            )}
            {regioPackageForecastNotAvailable && (
              <div className="forecast-row">
                <Trans>
                  Op dit moment is het nog niet mogelijk om een prognose te
                  tonen voor het Regio pakket. Zodra er voldoende data
                  beschikbaar is zal de prognose worden toegevoegd.
                </Trans>
              </div>
            )}
          </>
        )}
        {conversionGroups && (
          <div className="add-conversion-group">
            <Select.Search
              placeholder={i18n._(t`Kies een secundaire doelgroep`)}
              optionFilterProp="children"
              onSelect={handleChangeConversionGroup}
            >
              {conversionGroups?.map((conversionGroup) => (
                <Option
                  value={conversionGroup.code ?? ""}
                  key={conversionGroup.aka ?? ""}
                >
                  {conversionGroup.displayName}
                </Option>
              ))}
            </Select.Search>
            <Button
              mode="tertiary"
              disabled={!selectedConversionGroup}
              onClick={handleAddConversionGroup}
            >
              <Icons.PlusIcon fill="#008ccc" />
              <Trans>Voeg doelgroep toe</Trans>
            </Button>
          </div>
        )}
      </section>
    );
  }
);

export default Forecast;
