import { i18n } from "@lingui/core";
import { Trans, t } from "@lingui/macro";
import {
  Button,
  Checkbox,
  Container,
  Form,
  Input,
  Option,
  Select,
  Typography,
} from "@ster/ster-toolkit";
import { Form as AntForm, List } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { FormInstance } from "antd/lib/form/Form";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";

import {
  Advertiser,
  Organisation,
  PortalUser,
  SettingsResult,
  UserModel,
} from "../../api";
import { klantportalAdverteerder } from "../../utils/constants";
import { usePrevious } from "../../utils/hooks";
import styles from "./UserForm.module.less";

export const disabledClaims = Object.freeze([
  "aanvragen_formulier_tv",
  "aanvragen_formulier_radio",
  "aanvragen_formulier_inter",
  "facturen_tv",
  "facturen_radio",
  "facturen_inter",
  "documenten_upload",
  "documenten_upload_alles",
  "instructions_tv",
  "instructions_radio",
  "instructions_inter",
  "bookspot",
]);

const UserForm = memo(
  ({
    form,
    onSave,
    onInValidEmail,
    user,
    organisations,
    advertisers,
    settings,
    isEditAction,
  }: {
    form: FormInstance<UserModel>;
    onSave: (userModel: UserModel) => void;
    onInValidEmail: (email: string) => boolean;
    user?: PortalUser;
    organisations: Organisation[];
    advertisers: Advertiser[];
    settings: SettingsResult;
    isEditAction: boolean;
  }) => {
    const [isAdvertiser, setIsAdvertiser] = useState(
      user?.userInfo?.organisationCode === klantportalAdverteerder
    );
    const prevIsAdvertiser = usePrevious(isAdvertiser);

    const allClaims = useMemo(
      () =>
        (settings.claims ?? [])
          .filter(
            (c) => !isAdvertiser || !disabledClaims.includes(c.type as string)
          )
          .map((c) => c.type as string),
      [isAdvertiser, settings.claims]
    );

    const handleAllClaims = useCallback(
      ({ target: { checked } }: CheckboxChangeEvent) => {
        if (checked) {
          // alles aan
          form.setFieldsValue({ claims: allClaims });
        } else {
          form.setFieldsValue({ claims: [] });
        }
      },
      [allClaims, form]
    );

    const organisationCode = AntForm.useWatch("organisationCode", form);
    useEffect(() => {
      const isAdver = organisationCode === klantportalAdverteerder;
      if (prevIsAdvertiser !== isAdver) {
        // If the user is switching from advertiser to non-advertiser,
        // we need to remove all claims that are disabled for advertisers
        const claims = (form.getFieldValue("claims") ?? []) as string[];
        const previousAllClaims = (settings.claims ?? [])
          .filter(
            (c) =>
              !prevIsAdvertiser || !disabledClaims.includes(c.type as string)
          )
          .map((c) => c.type as string);
        const currentlyAllSelected = claims.length === previousAllClaims.length;
        const newClaimsValue = (
          currentlyAllSelected ? allClaims : claims
        ).filter((c) => !isAdver || !disabledClaims.includes(c));

        form.setFieldsValue({
          claims: newClaimsValue,
        });
      }
    }, [allClaims, form, organisationCode, prevIsAdvertiser, settings.claims]);

    useEffect(() => {
      form.setFieldsValue({
        active: user?.active ?? true,
        firstName: user?.userInfo?.firstName ?? "",
        lastName: user?.userInfo?.lastName ?? "",
        email: user?.email,
        phoneNumber: user?.phoneNumber,
        organisationCode: user?.userInfo?.organisationCode ?? "",
        advertiserCode: user?.userInfo?.advertiserCode ?? null,
        clientIdList: user?.userInfo?.clientIdList ?? null,
        claims: user?.claims ?? [],
        roles: user?.roles ?? [],
      });

      setIsAdvertiser(
        user?.userInfo?.organisationCode === klantportalAdverteerder
      );
    }, [form, user]);

    return (
      <>
        <Helmet>
          <title>
            {user && user.userInfo
              ? i18n._(t`Gebruiker: ${user.userInfo.fullName}`)
              : i18n._(t`Nieuwe gebruiker`)}
          </title>
        </Helmet>

        <Container>
          <Typography.Paragraph>
            <Trans>
              Wijzigingen in gebruikersgegevens, worden pas actief nadat de
              betreffende gebruiker opnieuw is ingelogd.
            </Trans>
          </Typography.Paragraph>
          <Form form={form} onFinish={onSave} scrollToFirstError>
            <Form.Item
              label={i18n._(t`E-mailadres`)}
              rules={[
                {
                  required: true,
                  message: i18n._(t`Veld is verplicht`),
                },
                {
                  required: true,
                  type: "email",
                  message: i18n._(t`Ongeldig e-mailadres`),
                },
                {
                  required: true,
                  validator: (rule, value): Promise<void> =>
                    !isEditAction && value && onInValidEmail(value)
                      ? Promise.reject(
                          new Error(i18n._(t`E-mailadres is al in gebruik`))
                        )
                      : Promise.resolve(),
                },
              ]}
              name="email"
            >
              <Input type="email" disabled={isEditAction} />
            </Form.Item>
            <Form.Item
              label={i18n._(t`Actief`)}
              name="active"
              valuePropName="checked"
            >
              <Checkbox>
                <Trans>Is deze gebruiker actief?</Trans>
              </Checkbox>
            </Form.Item>
            <Form.Item
              label={i18n._(t`Voornaam`)}
              rules={[
                { required: true, message: i18n._(t`Veld is verplicht`) },
              ]}
              name="firstName"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={i18n._(t`Achternaam`)}
              rules={[
                { required: true, message: i18n._(t`Veld is verplicht`) },
              ]}
              name="lastName"
            >
              <Input />
            </Form.Item>
            <Form.Item label={i18n._(t`Telefoonnummer`)} name="phoneNumber">
              <Input />
            </Form.Item>
            <Form.Item
              label={i18n._(t`Organisatie`)}
              rules={[
                { required: true, message: i18n._(t`Veld is verplicht`) },
              ]}
              name="organisationCode"
            >
              <Select.Search
                optionFilterProp="search"
                disabled={organisations.length === 0}
                placeholder={i18n._(t`Zoek een organisatie`)}
                allowClear
                onChange={(value): void =>
                  setIsAdvertiser(value === klantportalAdverteerder)
                }
                className={styles.fullwidth}
              >
                <Option key="all" value="">
                  <Trans>Alle organisaties</Trans>
                </Option>
                {organisations.map(({ name, code }) => (
                  <Option
                    key={code ?? ""}
                    value={code ?? ""}
                    search={`${name} ${code}`}
                  >
                    {name} ({code})
                  </Option>
                ))}
              </Select.Search>
            </Form.Item>
            {isAdvertiser && (
              <Form.Item label={i18n._(t`Adverteerder`)} name="advertiserCode">
                <Select.Search
                  optionFilterProp="search"
                  disabled={advertisers.length === 0 || !isAdvertiser}
                  placeholder={i18n._(t`Zoek een adverteerder`)}
                  allowClear
                  className={styles.fullwidth}
                >
                  <Option key="all" value="">
                    <Trans>Alle adverteerders</Trans>
                  </Option>
                  {advertisers.map(({ name, id }) => (
                    <Option key={id} value={id} search={`${name} ${id}`}>
                      {name}
                    </Option>
                  ))}
                </Select.Search>
              </Form.Item>
            )}
            <Form.Item
              label={i18n._(t`Client ID lijst`)}
              helpText={i18n._(
                t`Komma-gescheiden lijst van ClientID's (uit STARlight.NET)`
              )}
              name="clientIdList"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={i18n._(t`Rollen`)}
              rules={[
                { required: true, message: i18n._(t`Veld is verplicht`) },
              ]}
              name="roles"
            >
              <Checkbox.Group options={["User", "Admin", "Intermediair"]} />
            </Form.Item>
            <Form.Item
              label={i18n._(t`Rechten`)}
              extra={
                <Checkbox onChange={handleAllClaims}>
                  <Trans>Alle rechten aan-/uitzetten</Trans>
                </Checkbox>
              }
              dependencies={["organisationCode"]}
            >
              {() => (
                <Form.Item
                  noStyle
                  rules={[
                    { required: true, message: i18n._(t`Veld is verplicht`) },
                  ]}
                  name="claims"
                  dependencies={["organisationCode"]}
                >
                  <Checkbox.Group className={styles.claimsCheckboxes}>
                    <List
                      dataSource={settings.claims ?? []}
                      renderItem={(claim) => (
                        <List.Item key={claim.type}>
                          <Checkbox
                            value={claim.type}
                            disabled={
                              isAdvertiser &&
                              disabledClaims.includes(claim.type as string)
                            }
                          >
                            {claim.description}
                          </Checkbox>
                        </List.Item>
                      )}
                    />
                  </Checkbox.Group>
                </Form.Item>
              )}
            </Form.Item>

            <Form.Item>
              <Button htmlType="submit" mode="primary">
                <Trans>Opslaan</Trans>
              </Button>
            </Form.Item>
          </Form>
        </Container>
      </>
    );
  }
);
export default UserForm;
