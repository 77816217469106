/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { Age, AgeFromJSON, AgeFromJSONTyped, AgeToJSON } from "./";

/**
 *
 * @export
 * @interface AnalysisTargetGroupRadio
 */
export interface AnalysisTargetGroupRadio {
  /**
   *
   * @type {boolean}
   * @memberof AnalysisTargetGroupRadio
   */
  zb?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AnalysisTargetGroupRadio
   */
  hHVKND012?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AnalysisTargetGroupRadio
   */
  hhv?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AnalysisTargetGroupRadio
   */
  aB1?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AnalysisTargetGroupRadio
   */
  ehb?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AnalysisTargetGroupRadio
   */
  w1?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AnalysisTargetGroupRadio
   */
  b2B?: boolean;
  /**
   *
   * @type {Age}
   * @memberof AnalysisTargetGroupRadio
   */
  age: Age;
  /**
   *
   * @type {string}
   * @memberof AnalysisTargetGroupRadio
   */
  gender?: string | null;
}

export function AnalysisTargetGroupRadioFromJSON(
  json: any
): AnalysisTargetGroupRadio {
  return AnalysisTargetGroupRadioFromJSONTyped(json, false);
}

export function AnalysisTargetGroupRadioFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AnalysisTargetGroupRadio {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    zb: !exists(json, "zb") ? undefined : json["zb"],
    hHVKND012: !exists(json, "HHV+KND(0-12)")
      ? undefined
      : json["HHV+KND(0-12)"],
    hhv: !exists(json, "hhv") ? undefined : json["hhv"],
    aB1: !exists(json, "aB1") ? undefined : json["aB1"],
    ehb: !exists(json, "ehb") ? undefined : json["ehb"],
    w1: !exists(json, "w1") ? undefined : json["w1"],
    b2B: !exists(json, "b2B") ? undefined : json["b2B"],
    age: AgeFromJSON(json["age"]),
    gender: !exists(json, "gender") ? undefined : json["gender"],
  };
}

export function AnalysisTargetGroupRadioToJSON(
  value?: AnalysisTargetGroupRadio | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    zb: value.zb,
    "HHV+KND(0-12)": value.hHVKND012,
    hhv: value.hhv,
    aB1: value.aB1,
    ehb: value.ehb,
    w1: value.w1,
    b2B: value.b2B,
    age: AgeToJSON(value.age),
    gender: value.gender,
  };
}
