import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

import { AccountApi } from "../../api/apis";
import apiConfig from "../../apiConfig";
import { toError } from "../utils";
import { receiveAdvertisersAction } from "./actions";

// eslint-disable-next-line import/prefer-default-export
export function* receiveAdvertisers(): SagaIterator {
  try {
    const accountApi = new AccountApi(apiConfig());
    const response = yield call(() => accountApi.apiV1AccountAdvertisersGet());
    yield put(receiveAdvertisersAction.success(response));
  } catch (err) {
    yield put(receiveAdvertisersAction.failure(toError(err)));
  }
}
