import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Divider, Form, Input } from "@ster/ster-toolkit";
import { useForm } from "antd/es/form/Form";
import { Store } from "antd/lib/form/interface";
import classNames from "classnames";
import { memo, useCallback } from "react";

import { OrderRequest, SettingsResult } from "../../api";
import { validateForbiddenWords } from "../../utils/validators";
import DetailsCrm from "../campaignCreate/details/DetailsCrm";
import DetailsReadPeriodAdvertiser from "../campaignCreate/details/DetailsReadPeriodAdvertiser";
import { FormReadOnlyItem, formItemLayout } from "../partials/Form";
import styles from "./Details.module.less";

interface DetailsProps {
  order: OrderRequest;
  settings: SettingsResult;
  onUpdateOrder: (orderRequest: OrderRequest) => void;
}

const Details = memo(({ order, settings, onUpdateOrder }: DetailsProps) => {
  const { i18n } = useLingui();
  const [form] = useForm<OrderRequest>();

  form.setFieldsValue({ campaignName: order.campaignName });

  const handleChange = useCallback(
    (changedValues: Store) => {
      onUpdateOrder({ ...order, ...(changedValues as OrderRequest) });
    },
    [onUpdateOrder, order]
  );

  return (
    <Form
      {...formItemLayout}
      layout="horizontal"
      className={classNames(styles.form, formItemLayout.className)}
      name="details"
      form={form}
      onValuesChange={handleChange}
    >
      <DetailsReadPeriodAdvertiser
        order={order}
        productsByAdvertiser={[]}
        showDigitalReportWarning={Boolean(
          settings?.settings?.show_digital_report_warning
        )}
      />
      <DetailsCrm editable={false} {...order} />
      <Form.OptionalItem
        name="campaignName"
        label={i18n._(t`Campagnenaam`)}
        optionalLabel={i18n._(t`(optioneel)`)}
        rules={[{ validator: validateForbiddenWords(i18n) }]}
      >
        <Input
          placeholder={i18n._(t`Naam voor je campagne...`)}
          maxLength={30}
          className={styles.formInputWidth}
        />
      </Form.OptionalItem>
      <FormReadOnlyItem label={i18n._(t`Opmerkingen`)} value={order.comments} />
      <FormReadOnlyItem
        label={i18n._(t`Factuurnummer`)}
        value={order.invoice}
      />
      {order?.contact && (
        <>
          <Divider plain orientation="left">
            {i18n._(t`Contactpersoon`)}
          </Divider>
          <FormReadOnlyItem label={i18n._(t`Naam`)} value={order.contact} />
          <FormReadOnlyItem
            label={i18n._(t`E-mailadres`)}
            value={order.contactEmailExtra}
          />
        </>
      )}
    </Form>
  );
});

export default Details;
