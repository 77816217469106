import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { ContentContainer, Icons, Pageheader, Row } from "@ster/ster-toolkit";
import { Col, Space } from "antd";
import { parseJSON } from "date-fns";
import { memo, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";

import { MediumEnum } from "../../api";
import { receiveSalesPeriodsAction } from "../../store/campaignCreate/actions";
import {
  receiveCurrentSpotsAction,
  receiveTipsAction,
} from "../../store/dashboard/actions";
import Notification from "../Notification";
import CampaignAnalysisNmoNotification from "../partials/CampaignAnalysis/CampaignAnalysisNmoNotification";
import CurrentSpots from "./CurrentSpots";
import styles from "./DashboardContainer.module.less";
import Rates from "./Rates";
import SalesPeriods from "./SalesPeriods";
import { dashboardRootSelector } from "./selectors";
import TipSlider from "./TipSlider";

const DashboardContainer = memo(() => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const { account, loading, spotsByMedium, salesPeriods, settings, tips } =
    useSelector(dashboardRootSelector);

  useEffect(() => {
    dispatch(receiveSalesPeriodsAction.request());
    dispatch(receiveCurrentSpotsAction.request());
    dispatch(receiveTipsAction.request());
  }, [dispatch]);

  const showCampaignAnalysisNmoNotification = useMemo(
    () =>
      Boolean(
        settings.settings?.enable_campaign_analysis[MediumEnum.Tv] ?? true
      ) &&
      Boolean(
        settings.settings?.enable_campaign_analysis_nmo_notification ?? true
      ) &&
      settings.settings?.nmoSwitchDate,
    [
      settings.settings?.enable_campaign_analysis,
      settings.settings?.enable_campaign_analysis_nmo_notification,
      settings.settings?.nmoSwitchDate,
    ]
  );

  return (
    <>
      <Helmet>
        <title>{i18n._(t`Welkom`)}</title>
      </Helmet>

      <Pageheader
        title={<Trans>Welkom</Trans>}
        subTitle={account?.sterInlog?.firstName ?? undefined}
        icon={
          <Icons.StatsIcon
            width="100%"
            height="100%"
            fill="rgba(129, 176, 210, 0.2)"
          />
        }
      />

      <ContentContainer className="content">
        <Notification
          renderBefore={({ currentlyClosed, onClose }) =>
            showCampaignAnalysisNmoNotification ? (
              <CampaignAnalysisNmoNotification
                nmoSwitchDate={parseJSON(settings.settings?.nmoSwitchDate)}
                currentlyClosed={currentlyClosed}
                onClose={onClose}
              />
            ) : null
          }
        />
        <Row gutter={[40, 20]}>
          <Col md={24} lg={17}>
            <Space direction="vertical" className={styles.maxWidth}>
              {account &&
                account.claims &&
                account.claims.some((value) =>
                  ["campagnes_radio", "campagnes_tv"].includes(value)
                ) && (
                  <CurrentSpots
                    spotsByMedium={spotsByMedium}
                    loading={loading.currentSpotsLoading}
                  />
                )}
              <SalesPeriods
                salesPeriods={salesPeriods}
                loading={loading.salesPeriodsLoading}
                maximum={
                  (settings.settings
                    ?.max_salesperiods_on_dashboard as unknown as number) ?? 5
                }
              />
            </Space>
          </Col>
          <Col md={24} lg={7}>
            <TipSlider tips={tips} />
            <Space direction="vertical" className={styles.maxWidth}>
              <Rates />
            </Space>
          </Col>
        </Row>
      </ContentContainer>
    </>
  );
});

export default DashboardContainer;
