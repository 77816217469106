import { createAction, createAsyncAction } from "typesafe-actions";

import { PortalUser, TenantEnum } from "../../api/models";
import { ResponseError } from "../base";
import { actionNameAsync } from "../utils";

export const receiveMeAction = createAsyncAction(
  actionNameAsync("accountme", "REQUEST"),
  actionNameAsync("accountme", "SUCCESS"),
  actionNameAsync("accountme", "FAILURE")
)<undefined, PortalUser, ResponseError>();

export const logoutMeAction = createAsyncAction(
  actionNameAsync("logoutMe", "REQUEST"),
  actionNameAsync("logoutMe", "SUCCESS"),
  actionNameAsync("logoutMe", "FAILURE")
)<undefined, undefined, ResponseError>();

export const setCurrentTenantAction = createAction("SET_CURRENT_TENANT")<
  TenantEnum | undefined
>();
export const checkAndSetCurrentTenantAction = createAction(
  "CHECK_AND_SET_CURRENT_TENANT"
)();
