import "../styles.less";
import "./suborder.less";

import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  Channel,
  Divider,
  Icons,
  Tag,
  Tooltip,
  Typography,
  formatToEuro,
} from "@ster/ster-toolkit";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { MediumEnum, PackageChoiceEnum } from "../../../../api";
import { TagStatus, formatPercentage } from "../../../../utils";
import { getDistinctChannelNames } from "../../../../utils/channelNamesUtils";
import { useAvailableMediumTypes } from "../../../../utils/hooks";
import BookSpot from "../../../campaignDetail/BookSpot";
import OrderStatusTooltip from "../../Tooltips/OrderstatusTooltip/OrderStatusTooltip";
import CardStatistic from "../CardStatistic";
import { formatDatePeriod, formatTimePeriod } from "../utils";
import { SubOrderCardProps, baseSpotLength } from "./models";
import styles from "./SubOrderCardBody.module.less";
import SubOrderCardDetails from "./SubOrderCardDetails";
import SubOrderFooterOnline from "./SubOrderFooterOnline";

const SubOrderCard = ({
  medium,
  orderId,
  productId,
  cardData,
  spotsEditable,
  canSelectPreferredPosition,
  preferredPositionSurcharge,
  settings,
  isSpreaded,
}: SubOrderCardProps) => {
  const {
    id: subOrderId,
    period,
    _package,
    targetGroup,
    status,
    spotLength,
    grpPricePerMonth,
    cpmPrice,
    ctr,
    cvr,
    campaignName,
    packageChoice,
    timeBands,
    editableFields,
    hasMaterial,
  } = cardData;

  const channels = getDistinctChannelNames(cardData);

  const { Title } = Typography;
  const { AdultsIcon, CalendarIcon, InvoiceIcon, OnlineIcon, TimeIcon } = Icons;

  const { i18n } = useLingui();
  const [availableMediumTypes] = useAvailableMediumTypes("aanvragen_formulier");

  const formattedGrpMonthPrice =
    (grpPricePerMonth &&
      formatToEuro(grpPricePerMonth[0].grpPriceWithMonthIndex)) ||
    0;
  const formattedGrpMonthSpotLengthPrice =
    (grpPricePerMonth &&
      formatToEuro(grpPricePerMonth[0].grpPriceWithMonthAndSpotLengthIndex)) ||
    0;

  const formattedCpmPrice = formatToEuro(cpmPrice ?? 0);

  const formattedDatePeriod = formatDatePeriod(period.from, period.to);

  const totalSpotlength =
    spotLength && spotLength.filter((l) => l > 0).join("” + ");

  const navigate = useNavigate();
  const handleScheduleClick = useCallback(
    () => navigate(`/campaigns/schedule/${medium}/${orderId}/${cardData.id}`),
    [cardData.id, medium, navigate, orderId]
  );

  const editCampaign = useCallback(() => {
    navigate(`/campaigns/edit/${medium}/${orderId}/${subOrderId}`);
  }, [medium, navigate, orderId, subOrderId]);

  const isEditable = useMemo(
    () =>
      !isSpreaded &&
      (editableFields?.length ?? 0) > 0 &&
      availableMediumTypes.includes(medium) &&
      settings.settings?.enable_edit_campaign?.[medium],
    [
      availableMediumTypes,
      editableFields?.length,
      isSpreaded,
      medium,
      settings.settings?.enable_edit_campaign,
    ]
  );

  return (
    <article className="card card--suborder">
      <header className="card-header">
        <div>
          <Title level={2} className="card-title">
            {_package.name}
            {totalSpotlength && ` ${totalSpotlength}”`}
          </Title>
          {campaignName && (
            <span className={styles.campaignName}>{campaignName}</span>
          )}
        </div>
        <div className="card-header__menu">
          {hasMaterial === false && (
            <Tag
              status={TagStatus.Warning}
              text={i18n._(t`Geen materiaal aanwezig`)}
            />
          )}
          <OrderStatusTooltip status={status} />
        </div>
      </header>

      <SubOrderCardDetails
        medium={medium}
        startDate={period.from}
        endDate={period.to}
        {...cardData}
      />

      <Divider />

      <section className="card-row">
        {packageChoice !== PackageChoiceEnum.FixedCosts && (
          <>
            {medium === MediumEnum.Inter ? (
              <div className="suborder-stat">
                <OnlineIcon width={40} height={40} />
                <CardStatistic
                  label={
                    <Tooltip title={i18n._(t`Clickthrough rate`)}>
                      <span>
                        <Trans>CTR</Trans>
                      </span>
                    </Tooltip>
                  }
                  value={formatPercentage(ctr ?? 0)}
                />
                <CardStatistic
                  label={
                    <Tooltip title={i18n._(t`View through rate`)}>
                      <span>
                        <Trans>VTR</Trans>
                      </span>
                    </Tooltip>
                  }
                  value={formatPercentage(cvr ?? 0)}
                />
              </div>
            ) : (
              <div className="suborder-stat">
                <AdultsIcon width={40} height={40} />
                <CardStatistic
                  label={i18n._(t`Doelgroep`)}
                  value={targetGroup.targetGroupId ?? ""}
                />
              </div>
            )}
            <div className="suborder-stat">
              <InvoiceIcon width={40} height={40} />
              {medium === MediumEnum.Inter ? (
                <CardStatistic
                  label={
                    totalSpotlength
                      ? i18n._(t`CPM-prijs ${totalSpotlength}”`)
                      : i18n._(t`CPM-prijs`)
                  }
                  value={formattedCpmPrice}
                />
              ) : (
                <CardStatistic
                  label={i18n._(t`GRP-prijs ${baseSpotLength[medium]}”`)}
                  value={formattedGrpMonthPrice}
                />
              )}
            </div>
            {medium !== MediumEnum.Inter &&
              baseSpotLength[medium] !==
                spotLength?.reduce((a, b) => a + b, 0) && (
                <div className="suborder-stat">
                  <InvoiceIcon width={40} height={40} />
                  <CardStatistic
                    label={i18n._(t`GRP-prijs ${totalSpotlength}”`)}
                    value={formattedGrpMonthSpotLengthPrice}
                  />
                </div>
              )}
          </>
        )}
        <div className="suborder-stat">
          <CalendarIcon width={40} height={40} />
          <CardStatistic
            label={i18n._(t`Periode`)}
            value={formattedDatePeriod}
          />
        </div>
        {timeBands && timeBands[0] && (
          <div className="suborder-stat">
            <TimeIcon width={40} height={40} />
            <CardStatistic
              label={i18n._(t`Tijdvak`)}
              value={formatTimePeriod(timeBands[0])}
            />
          </div>
        )}
        {medium === MediumEnum.Radio && (
          <div className="suborder-stat channels">
            <CardStatistic
              value={channels?.map((channel) => (
                <Channel key={channel} width={50} height={50} type={channel} />
              ))}
            />
          </div>
        )}
      </section>

      <footer className="card-footer card-row">
        {isEditable && (
          <Button
            mode="secondary"
            onClick={editCampaign}
            className={styles.button}
          >
            <Trans>Wijzigen</Trans>
          </Button>
        )}
        {medium === MediumEnum.Inter ? (
          <SubOrderFooterOnline
            orderId={orderId}
            subOrderId={subOrderId}
            isReady={status === "Ready"}
          />
        ) : (
          <>
            <Button mode="tertiary" onClick={handleScheduleClick}>
              <Trans>Uitzendschema</Trans>
            </Button>
            {spotsEditable && (
              <BookSpot
                medium={medium}
                productId={productId}
                subOrder={cardData}
                orderId={orderId}
                canSelectPreferredPosition={canSelectPreferredPosition}
                preferredPositionSurcharge={preferredPositionSurcharge}
              />
            )}
          </>
        )}
      </footer>
    </article>
  );
};

export default SubOrderCard;
