import { Trans } from "@lingui/macro";
import { Alert } from "antd";
import moment from "moment";
import { memo, useMemo } from "react";

import { MediumEnum, PackageType } from "../../api";
import { Top2000WarningProps } from "./models";
import styles from "./Top2000Warning.module.less";

const Top2000Warning = memo(
  ({
    medium,
    from,
    to,
    _package,
    channels,
    className,
  }: Top2000WarningProps) => {
    const showWarning = useMemo(() => {
      const currentYear = from.getFullYear();
      return (
        medium === MediumEnum.Radio &&
        (!channels || channels.indexOf("R2") !== -1) &&
        moment(`${currentYear}-12-25`).isSameOrBefore(moment(to), "day") &&
        moment(`${currentYear}-12-31`).isSameOrAfter(moment(from), "day")
      );
    }, [channels, from, medium, to]);

    const isTop2000Package = useMemo(
      () => _package?.type === PackageType.Top2000,
      [_package]
    );

    const showRadio2Warning = useMemo(
      () => _package?.isCombi || _package?.type === PackageType.AlgemeenNut,
      [_package]
    );

    if (!showWarning || isTop2000Package) {
      return null;
    }

    return showRadio2Warning ? (
      <Alert
        showIcon
        className={`${styles.top2000} ${className}`}
        type="warning"
        message=""
        description={
          <Trans>
            Let op: dit pakket geldt normaliter voor 4 radiozenders, waaronder
            NPO Radio 2. Van 25 t/m 31 december wordt op die zender echter de
            Top 2000 uitgezonden, waarvoor andere inkoopopties gelden. Je
            campagne zal dan ook niet uitgeleverd worden op NPO Radio 2.
            Geïnteresseerd in de inkoopopties rondom de Top 2000? Neem contact
            op met je accountmanager of bekijk onze{" "}
            <a
              href="https://www.ster.nl/top2000"
              rel="noopener noreferrer"
              target="_blank"
            >
              website
            </a>
            .
          </Trans>
        }
      />
    ) : (
      <Alert
        showIcon
        className={`${styles.top2000} ${className}`}
        type={_package ? "error" : "warning"} // In de deelorder tonen we deze melding als error
        message=""
        description={
          <Trans>
            Let op: de Top 2000 op NPO Radio 2 vindt plaats van 25 t/m 31
            december. In deze periode zijn er geen reguliere pakketten, maar is
            het wel mogelijk Top2000 Ster Time (met of zonder spreiding) of Your
            Time in te kopen.
            <br />
            Meer informatie over deze inkoopopties vind je op onze{" "}
            <a
              href="https://www.ster.nl/top2000"
              rel="noopener noreferrer"
              target="_blank"
            >
              website
            </a>
            .
          </Trans>
        }
      />
    );
  }
);

export default Top2000Warning;
