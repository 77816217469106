import { AnyAction } from "redux";
import { Reducer } from "typesafe-actions";

import { genericReducer } from "../../utils";
import { LoadingForecastsForProposal } from "../models";
import { receiveForecastsForProposalAction } from "./actions";

// eslint-disable-next-line import/prefer-default-export
export const forecastsForProposalReducer: Reducer<
  LoadingForecastsForProposal,
  AnyAction
> = genericReducer(
  receiveForecastsForProposalAction.request,
  receiveForecastsForProposalAction.success,
  receiveForecastsForProposalAction.failure,
  "forecasts"
);
