import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

import { AccountApi } from "../../api/apis";
import apiConfig from "../../apiConfig";
import { toError } from "../utils";
import { receiveOrganisationsAction } from "./actions";

// eslint-disable-next-line import/prefer-default-export
export function* receiveOrganisations(): SagaIterator {
  try {
    const accountApi = new AccountApi(apiConfig());
    const response = yield call(() =>
      accountApi.apiV1AccountOrganisationsGet()
    );
    yield put(receiveOrganisationsAction.success(response));
  } catch (err) {
    yield put(receiveOrganisationsAction.failure(toError(err)));
  }
}
